import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import Select from 'components/Select';
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import { CreateMovimentFormValidationSchema } from './CreateFormValidations';
import { ICreateMovimentFormData, IProductMoviment, ITruckMoviment, IprofessionalMoviment } from 'pages/Moviment/constants/types';
import { listOptionProducts, listOptionProfessionals, listOptionTrucks } from 'pages/Moviment/graphql/MovimentQuery';
import { IProduct } from 'pages/Stock/constants/types';
import { getProduct } from 'services/stock/graphQL';

export type ICreateMovimentFormProps = {
    onSubmit: (payload: ICreateMovimentFormData) => void,
    listMoviments: ICreateMovimentFormData[]
}

export const CreateMovimentFormId = 'create-moviment-form';

const CreateMovimentForm: React.FC<ICreateMovimentFormProps> = ({
    onSubmit,
    listMoviments,
}) => {

    const [searchProductInput, setSearchProductInput] = useState('')
    const [productOptions, setProductOptions] = useState([] as any)
    const [selectedProduct, setSelectedProduct] = useState([] as any)
    const [productError, setProductError] = useState("");

    const [selectedReason, setSelectedReason] = useState([] as any)
    const [reasonError, setReasonError] = useState("");

    const [searchRecipientInput, setSearchRecipientInput] = useState('')
    const [recipientOptions, setRecipientOptions] = useState([] as any)
    const [selectedRecipient, setSelectedRecipient] = useState([] as any)
    const [recipientError, setRecipientError] = useState("");

    const [selectStockAction, setSelectedStockAction] =  useState([] as any)
    const [stockActionError, setStockActionError] = useState("");
    const [productQuantityRecipient, setProductQuantityRecipient] =  useState([] as any)
    const [productQuantityStock, setProductQuantityStock] =  useState([] as any)
    const [stockUnity, setStockUnity] =  useState([] as any)


    const createMovimentFormRef = useRef<FormHandles>(null);

    useEffect(() => {
        let timeoutProduct: NodeJS.Timeout;
        const fatchProducts = async () => {
            const productList = await listOptionProducts(searchProductInput)
            setProductOptions(productList);
        }
        const delayedFetchData = () => {
            clearTimeout(timeoutProduct);
            timeoutProduct = setTimeout(fatchProducts, 400);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutProduct);
      }, [searchProductInput]);

    useEffect(() => {
        let timeoutRecipient: NodeJS.Timeout;
        const fatchRecipients = async () => {
            if(selectedReason.value === 'RESALE'){
                const recipientList = await listOptionProfessionals(searchRecipientInput)
                setRecipientOptions(recipientList)
            } else {
                const recipientList = await listOptionTrucks(searchRecipientInput)
                setRecipientOptions(recipientList)
            }
        }
        fatchRecipients
        const delayedFetchData = () => {
            clearTimeout(timeoutRecipient);
            timeoutRecipient = setTimeout(fatchRecipients, 400);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutRecipient);
    }, [searchRecipientInput, selectedReason]);

    useEffect(() => {
        const productInRecipient = selectedRecipient.products && selectedRecipient.products.find((product: { quantity: number, id: number}) => (product.id === selectedProduct.id))?.quantity || 0
        const totalInRecipient = listMoviments.reduce((total, item)=>  {
            if(item.product.id === selectedProduct.id && item.recipient.value === selectedRecipient.value){
                if(item.stockAction === 'DECREASE'){
                    return total - item.numberOfProducts
                }
                return total + item.numberOfProducts
            }
            return total
        }, productInRecipient)
        setProductQuantityRecipient(totalInRecipient)
    }, [selectedProduct, selectedReason, selectedRecipient]);

    useEffect(() => {
        const unity = selectedProduct.stock_unit || [] as any
        setStockUnity(unity)
        const ProductInStock = listMoviments.reduce((total, item)=>  {
            if(item.product.id === selectedProduct.id){
                if(item.stockAction === 'INCREASE'){
                    return total - item.numberOfProducts
                }
                return total + item.numberOfProducts
            }
            return total
        }, selectedProduct.quantity )
        setProductQuantityStock(ProductInStock || 0)
    }, [selectedProduct]);

    const reasonOptions = [
        { value: "RESALE", label: "Revenda" },
        { value: "USE", label: "Uso" },
      ]

    const stockActionOptions = [
        { value: "DECREASE", label: "Retirar" },
        { value: "INCREASE", label: "Adicionar" }
      ]

      const normalizeStockUnity = (stockUnity: string) => {
        const normalizationMap: Record<string, string> = {
          "LITER": "Litro",
          "MILLILITRE": "Mililitro",
          "GRAM": "Grama",
          "KILOGRAM": "Quilograma",
          "MILIGRAM": "Miligrama",
          "UNITY": "Unidade"
        };
    
        return normalizationMap[stockUnity];
      };

    useEffect(() => {
        setSelectedRecipient([] as any)
    }, [selectedReason]);

    const clearError = () => {
        setProductError("")
        setReasonError("")
        setStockActionError("")
        setRecipientError("")
    }

    const resetForm = () => {
        setSearchProductInput('')
        setSearchRecipientInput('')
        setSelectedRecipient([] as any)
        setSelectedReason([] as any)
        setSelectedProduct([] as any)
        setSelectedStockAction([] as any)
        clearError()
        createMovimentFormRef.current?.setData({
            numberOfProducts: '',
        });

    }

    let quantityDiference: number | undefined

    const verifyQuantity = async(moviment: ICreateMovimentFormData, listMoviments: ICreateMovimentFormData[]) =>{
        if(moviment.stockAction === 'INCREASE'){
            const product: IProduct = await getProduct(selectedProduct.id)
            const stockQuantity = product.quantity
            const totalQuantity = listMoviments.reduce((total, item)=>  {
                if(item.product.id === selectedProduct.id){
                    if(item.stockAction === 'INCREASE'){
                        return total + item.numberOfProducts
                    }
                    return total - item.numberOfProducts
                }
                return total
            }, Number(moviment.numberOfProducts))
            const quantityDifference = stockQuantity - totalQuantity
            return quantityDifference
        } else if(moviment.stockAction === 'DECREASE'){
            const recipientQuantity = selectedRecipient.products && selectedRecipient?.products.find((product: { quantity: number, id: number}) => (product.id === selectedProduct.id))?.quantity || 0
            const totalQuantity = listMoviments.reduce((total, item)=>  {
                console.log(item)
                if(item.product.id === selectedProduct.id && item.recipient.value === selectedRecipient.value){
                    if(item.stockAction === 'DECREASE'){
                        return total + item.numberOfProducts
                    }
                    return total - item.numberOfProducts
                }
                return total
            }, Number(moviment.numberOfProducts))
            console.log(recipientQuantity, totalQuantity)
            const quantityDifference = recipientQuantity - totalQuantity
            return quantityDifference
        }
    }

    const handleSubmit = async (
        payload: ICreateMovimentFormData,
    ) => {
             quantityDiference = await verifyQuantity(payload, listMoviments) 
        try {
            if(quantityDiference && quantityDiference < 0) {
                throw new Error("Quantidade insuficiente para movimentação")
            }
            await CreateMovimentFormValidationSchema.validate({ productId: selectedProduct.id, numberOfProducts: Number(payload.numberOfProducts), reason: selectedReason.value, recipientId: selectedRecipient.value, stockAction: selectStockAction.value}, { abortEarly: false });
            onSubmit({ product: selectedProduct, numberOfProducts: Number(payload.numberOfProducts), reason: selectedReason, recipient: selectedRecipient, stockAction: payload.stockAction});
            resetForm()
        } catch (error) {
            if(quantityDiference && quantityDiference < 0){
                launchToast("Quantidade insuficiente para movimentação", "error");
            } else{
                const errors = getValidationErrors(error as ValidationError);
                createMovimentFormRef.current?.setErrors(errors);
                launchToast("Verifique o preenchimento dos dados", "error");
            }
            clearError()
            if(!selectedProduct.id){setProductError("Selecione um produto")}
            if(!selectedReason.value){setReasonError("Selecione um motivo")}
            if(!selectStockAction.value){setStockActionError("Selecione uma ação")}
            if(!selectedRecipient.value){setRecipientError("Selecione um destinatario")}
        }
    };  

    return (
        <>
            <Form 
                onSubmit={handleSubmit} ref={createMovimentFormRef} id={CreateMovimentFormId}
            >
                <Styles.row>
                    <Styles.field>
                        <Label>Produtos</Label>
                        <Select
                            name="product"
                            options={productOptions?.map((product:  IProductMoviment) => ({
                            ...product,
                            label: product.name
                            })) as { value: string; label: string; }[]}
                            onInputChange={setSearchProductInput}
                            error={productError}
                            placeholder="Busque um produto"
                            onChange={(e) => setSelectedProduct(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Unidade de Medida</Label>
                        <InputRef
                            name="stockUnity"
                            placeholder="Unidade de medida"
                            containerStyle={{ width: "33rem" }}
                            value={normalizeStockUnity(stockUnity)}
                            disabled
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Quantidade em estoque</Label>
                        <InputRef
                            name="stockQuantity"
                            placeholder="Quantidade em estoque"
                            containerStyle={{ width: "33rem" }}
                            value={productQuantityStock}
                            disabled
                        />
                    </Styles.field>
                    </Styles.row>
                    <Styles.row>
                    <Styles.field>
                        <Label>Motivo</Label>
                        <Select
                            name="reason"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione um motivo"
                            options={reasonOptions}
                            error={reasonError}
                            value={selectedReason}
                            onChange={(e) => setSelectedReason(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Destinatario</Label>
                            <Select
                                name="recipient"
                                options={ selectedReason.value === 'RESALE' ?
                                    recipientOptions?.map((recipient:  IprofessionalMoviment) => ({
                                        ...recipient,
                                        label: recipient?.user?.name,
                                        value: recipient?.user?.id
                                    })) :
                                    recipientOptions?.map((recipient: ITruckMoviment) => ({
                                        ...recipient,
                                        label: recipient?.chasi_number,
                                        value: recipient?.id
                                    }))
                                }
                                onInputChange={setSearchRecipientInput}
                                error={recipientError}
                                placeholder={selectedReason.value === 'RESALE' ? 
                                    "Busque um profissional" :
                                    "Busque um truck"
                                }
                                onChange={(e) => setSelectedRecipient(e)}
                                isDisabled={selectedReason.length === 0}
                            />
                    </Styles.field>
                    <Styles.field>
                        <Label>Quantidade no destinatario</Label>
                        <InputRef
                            name="productQuantityRecipient"
                            placeholder="Quantidade no destino"
                            containerStyle={{ width: "33rem" }}
                            value={productQuantityRecipient}
                            disabled
                        />
                    </Styles.field>
                    </Styles.row>
                    <Styles.row>
                    <Styles.field>
                        <Label>Ação</Label>
                        <Select
                            name="stockAction"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma ação"
                            options={stockActionOptions}
                            error={stockActionError}
                            value={selectStockAction}
                            onChange={(e: any) => setSelectedStockAction(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Quantidade a transferir</Label>
                        <InputRef
                            name="numberOfProducts"
                            type='number'
                            placeholder="Selecione a quantidade"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default CreateMovimentForm