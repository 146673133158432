import { gql } from "@apollo/client";
import client from "../../../services/api-graphql";
import IAppointment from "../../../types/IAppointment";
import { IAppointmentFiltersFormData } from "./forms/AppointmentFinancialFiltersForm/types";
import { ITotalResumePricesProps } from "./types";

type IProps = {
  filters: IAppointmentFiltersFormData;
  pagination: boolean;
};

type IPropsFinantial = {
  filters: IAppointmentFiltersFormData;
};

type IResults = {
  appointmentsResumePricesReports: ITotalResumePricesProps;
  appointments: { totalPages: number; appointments: IAppointment[] };
};

type IResultsFinantial = {
  appointmentsFinantialResumePricesReports:  {appointmentsData: IAppointmentsData[], finantialReport: ITotalResumePricesProps;}
};

export type IAppointmentsData = {
  appointment_id: string
  observations: string
  on_the_way_status_hour: string
  on_local_status_hour: string
  service_completed_status_hour: string
  date: string
  period: string
  hour: string
  status: number
  appointment_service_price: number
  appointment_paid_price: number
  power_supply: number
  created_at: Date
  execution_time: number
  pack_frequency: string
  platform: number
  order_paid_price: string
  discount_by_admin: number
  total_discount: number
  coupon_code: string
  customer_name: string
  customer_cpf: string
  professional_user_id: string
  professional_name: string
  pet_name: string
  pet_gender: string
  pet_weight: number
  breed_id: string
  breed_name: string
  breed_size: string
  species_id: string
  species_name: string
  cep: string
  street: string
  neighborhood: string
  number: string
  complement: string
  service_name: string
  transaction_ids: string[]
  transaction_pagseguro_ids: string[]
  providers: string[]
  types: number[]
  item_prices: number[]
  appointment_item_paid_prices: number[]
  item_names: string[]
}

const loadReportResumePrices = async ({
  filters,
  pagination,
}: IProps): Promise<IResults> => {
  const results = await client.query<IResults>({
    query: gql`
      query (
        $to: String!
        $from: String!
        $filters: FiltersAppointmentInputType
      ) {
        appointmentsResumePricesReports(to: $to, from: $from) {
          total
          details_by_date {
            date
            total
          }
          details_by_professional {
            professional_name
            total
          }
        }
        appointments(filters: $filters) {
          totalPages
          appointments {
            id
            observations
            on_the_way_status_hour
            service_completed_status_hour
            date
            period
            hour
            status
            service_price
            paid_price
            power_supply
            created_at
            execution_time
            pack_frequency
            order {
              platform
              paid_price
              discount_by_admin
              total_discount
              transactions {
                id
                transaction_pagseguro_id
                provider
                type
              }
              appointments {
                id
              }
              coupon_relation {
                code
              }
            }
            customer {
              user {
                name
                cpf
              }
            }
            professional {
              id
              user {
                id
                name
              }
            }
            pet {
              name
              gender
              breed {
                id
                name
                size
              }
              specie {
                id
                name
              }
              weight
            }
            address {
              cep
              street
              neighborhood
              number
            }
            service {
              name
            }
            appointments_items {
              item_price
              paid_price
              item {
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      from: filters.start_date,
      to: filters.end_date,
      filters: {
        where: {
          status: filters.status,
          categories: filters.categories,
          tutor_name: filters.tutor_name,
          from: filters.start_date,
          to: filters.end_date,
        },
        ...(pagination && {
          pagination: {
            limit: 20,
            page: 1,
          },
        }),
        orderBy: [
          {
            date: "asc",
          },
          {
            period: "asc",
          },
          {
            hour: "asc",
          },
        ],
      },
    },
  });
  return results.data;
};

const loadAppointmentsByRange = async ({
  filters,
  page,
}: {
  filters: IAppointmentFiltersFormData;
  page: number;
}): Promise<{ appointments: IAppointment[], totalPages: number}> => {
  const results = await client.query<{
    appointments: { totalPages: number; appointments: IAppointment[] };
  }>({
    query: gql`
      query ($filters: FiltersAppointmentInputType) {
        appointments(filters: $filters) {
          totalPages
          appointments {
            id
            observations
            on_the_way_status_hour
            service_completed_status_hour
            date
            period
            hour
            status
            service_price
            paid_price
            power_supply
            order {
              platform
              coupon_relation {
                code
              }
            }
            customer {
              user {
                name
                cpf
              }
            }
            professional {
              id
              user {
                id
                name
              }
            }
            pet {
              name
              gender
              breed {
                id
                name
                size
              }
              specie {
                id
                name
              }
              weight
            }
            address {
              cep
              street
              neighborhood
              number
            }
            service {
              name
            }
            appointments_items {
              item_price
              paid_price
              item {
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      filters: {
        where: {
          status: filters.status,
          categories: filters.categories,
          tutor_name: filters.tutor_name,
          from: filters.start_date,
          to: filters.end_date,
        },
        pagination: {
          limit: 20,
          page: page,
        },
        orderBy: [
          {
            date: "asc",
          },
          {
            period: "asc",
          },
          {
            hour: "asc",
          },
        ],
      },
    },
  });

  return results.data.appointments;
};

const loadReportResumeAppointmentsFinantial = async ({
  filters,
}: IPropsFinantial): Promise<IResultsFinantial> => {
  const results = await client.query<IResultsFinantial>({
    query: gql`
      query AppointmentsFinantialResumePricesReports($filters: GetAppointmentsFinantialReportsFiltersInputTypes!) {
  appointmentsFinantialResumePricesReports(filters: $filters) {
    appointmentsData {
      appointment_id
      observations
      on_the_way_status_hour
      on_local_status_hour
      service_completed_status_hour
      date
      period
      hour
      status
      appointment_service_price
      appointment_paid_price
      power_supply
      created_at
      execution_time
      pack_frequency
      platform
      order_paid_price
      discount_by_admin
      total_discount
      coupon_code
      customer_name
      customer_cpf
      professional_user_id
      professional_name
      pet_name
      pet_gender
      pet_weight
      breed_id
      breed_name
      breed_size
      species_id
      species_name
      cep
      street
      neighborhood
      number
      complement
      service_name
      transaction_ids
      transaction_pagseguro_ids
      providers
      types
      item_prices
      appointment_item_paid_prices
      item_names
    }
    finantialReport {
      total
      details_by_date {
        date
        total
      }
      details_by_professional {
        professional_name
        total
      }
    }
  }
}`,
    variables: {
      filters: {
          from: filters.start_date,
          to: filters.end_date,
          categories: filters.categories,
          tutor_name: filters.tutor_name,
          status: filters.status
      },
    },
  });
  return results.data;
};

export { loadAppointmentsByRange, loadReportResumePrices, loadReportResumeAppointmentsFinantial };

