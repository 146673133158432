import React, { useCallback, useEffect, useRef, useState } from "react";

import Text from "../../../components/Text";
import { Form } from "@unform/web";
import InputRef from "../../../components/InputRef";
import Button from "../../../components/Button";
import colors from "../../../shared/utils/constants/colors";

import * as Styles from "./styles";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import Modal from "../../../components/Modal";
import masks from "../../../shared/utils/masks";
import Select from "../../../components/Select";
import Tab from "../../../components/Tab/index";
import { Tabs, Content } from "../../../components/Tab/styles";

import { getRoles } from "../../../services/api-graphql-calls";
import { insertUser, updateUser } from "../../../services/user/UserService";
import { launchToast } from "../../../shared/utils/launchToast";
import getValidationErrors from "../../../shared/utils/getValidationErrors";
import { Label } from "../../../components/Label/styles";
import CustommerModal from "./CustommerModal";
import cepPromise from "cep-promise";
import ufs from "../../../shared/utils/constants/ufs";
import ProfessionalModal from "./ProfessionalModal";

interface UserFormData {
  firstName: string;
  lastName: string;
  email: string;
  cpf: string;
  phone: string;
  birthday: string;
  cep: string;
  number: string;
  city: string;
  neighborhood: string;
  street: string;
  state: string;
  surname: string;
  complement: string;
  role: any;
}
const UserModal = ({ visible, onCloseModal, userRecivied }: any) => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [activedTab, setActivedTab] = useState(0);
  const [roles, setRoles] = useState([] as any);
  const [selectedRoles, setSelectedRoles] = useState([] as any);
  const [selectedProfile, setSelectedProfile] = useState([] as any);
  const [selectedProfession, setSelectedProfession] = useState([] as any);
  const [roleError, setRoleError] = useState([] as any);
  const [professionError, setProfessionError] = useState([] as any);
  const [userId, setUserId] = useState("");
  const [update, setUpdate] = useState(false);
  const [editing, setEditing] = useState(true);
  const [user, setUser] = useState({} as any);

  const setFormFields = useCallback(
    (user: any) => {
      setEditing(false);
      formRef.current?.setData(user);
      formRef.current?.setFieldValue("cpf", applyMaskSring(user.cpf, "cpf"));
      formRef.current?.setFieldValue(
        "phone",
        applyMaskSring(user.phone, "phone")
      );

      let selectProfile = [] as any;
      for (let role of user.roles) {
        let auxRole = roles.find((r: any) => {
          return role.id === r.value;
        });
        if (!!auxRole.value) {
          selectProfile.push(auxRole);
        }
      }
      const normalizeSelectProfile = filterAndNormalizeRoles(selectProfile);
      setSelectedProfile(normalizeSelectProfile);

      let selectProfession = [] as any;
      for (let role of user.roles) {
        let auxRole = roles.find((r: any) => {
          return role.id === r.value;
        });
        if (!!auxRole.value) {
          selectProfession.push(auxRole);
        }
      }
      const nomalizeSelectProfession =
        filterAndNormalizeProfissional(selectProfession);
      setSelectedProfession(nomalizeSelectProfession);

      const { addresses, address_main_id } = user;
      if (address_main_id) {
        const address =
          addresses.find((a: any) => a.id === address_main_id) || addresses[0];
        formRef.current?.setData(address);
        formRef.current?.setFieldValue(
          "cep",
          applyMaskSring(address.cep, "cep")
        );
      }
    },
    [roles]
  );

  useEffect(() => {
    setActivedTab(0);
    formRef.current?.reset();
    formRef.current?.setErrors({});
    setRoleError("");
    setProfessionError("");
    if (!!userRecivied.id) {
      const { id } = userRecivied;
      setUserId(id);
      setUpdate(true);
      setUser(userRecivied);
      setFormFields(userRecivied);
    } else {
      formRef.current?.reset();
      setUpdate(false);
      setSelectedRoles([]);
      setSelectedProfile([]);
      setSelectedProfession([]);
    }
  }, [userRecivied, setFormFields]);

  const loadRoles = useCallback(async () => {
    const results = (await getRoles()) as any;

    const { roles } = results;

    let rolesForSelect = roles.map((r: any) => ({
      value: r.id,
      label: r.name,
    }));

    setRoles(rolesForSelect);
  }, []);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  const handleSubmit = async (data: UserFormData) => {
    setLoading(true);

    try {
      formRef.current?.setErrors({});

      // const includeProfissonal = selectedRoles.some((role: { value: string, label: string }) => role.label === 'Profissional')

      const schema = Yup.object().shape({
        firstName: Yup.string()
          .trim()
          .min(1, "Nome não pode estar vazio")
          .required("Nome é obrigatório"),
        lastName: Yup.string()
          .trim()
          .min(1, "Sobrenome não pode estar vazio")
          .required("Sobrenome é obrigatório"),
        email: Yup.string()
          .trim()
          .required("Email é obrigatório")
          .email("Email inválido"),
        cpf: Yup.string().required("CPF é obrigatório"),
        phone: Yup.string()
          .trim()
          .required("Telefone celular é obrigatório")
          .min(15, "Informe um número válido"),
        cep: Yup.string().required("CEP é obrigatório"),
        number: Yup.string().required("Número é obrigatório"),
        city: Yup.string().required("Cidade é obrigatória"),
        street: Yup.string().required("Endereço é obrigatório"),
        state: Yup.string()
          .required("Estado é obrigatório")
          .oneOf(ufs, "Informe um estado válido"),
        neighborhood: Yup.string().required("Bairro é obrigatório"),
        birthday: Yup.string().required("Data de nascimento é obrigatória"),
        roles: Yup.array()
          .min(1, "Selecione pelo menos um perfil")
          .required("Selecione pelo menos um perfil"),
        professional: Yup.array().when("roles", {
          is: (roles) =>
            roles.some((role: { label: string }) => {
              return role.label === "Profissional";
            }),
          then: Yup.array().min(1, "Selecione pelo menos uma profissão"),
          otherwise: Yup.array().notRequired(),
        }),
      });

      await schema.validate(
        { ...data, roles: selectedRoles, professional: selectedProfession },
        { abortEarly: false }
      );

      if (selectedRoles === null || selectedRoles?.length === 0) {
        setRoleError("Selecione ao menos um perfil");
      } else {
        setRoleError("");
      }

      if (selectedProfession === null || selectedProfession?.length === 0) {
        setProfessionError("Selecione ao menos uma profissão");
      } else {
        setProfessionError("");
      }

      try {
        const { firstName, lastName, ...restOfData } = data;
        if (!update) {
          let roles = selectedRoles
            ? selectedRoles.map((r: any) => ({ role_id: r.value }))
            : [];
          const resp = (await insertUser(
            { name: `${firstName.trim()} ${lastName.trim()}`, ...restOfData },
            roles
          )) as any;
          setUserId(resp.id);
          setUser(resp);
          setUpdate(true);
          navigator.clipboard.writeText("Zazuu123");
          setLoading(false);
          launchToast("Usuário incluído com sucesso", "success");
          launchToast("Senha copiada para a área de transferência", "success");
        } else {
          let roles = selectedRoles
            ? selectedRoles.map((r: any) => ({ id: r.value }))
            : [];

          const resp = await updateUser(userId, data, roles);
          setUser(resp);

          launchToast("Usuário editado com sucesso", "success");
        }
      } catch (error: any) {
        launchToast(error.message, "error");
      }
    } catch (error) {
      // @ts-ignore:next-line
      const errors = getValidationErrors(error);

      if (selectedRoles === null || selectedRoles?.length === 0) {
        setRoleError("Selecione ao menos um perfil");
      } else {
        setRoleError([]);
      }

      if (selectedProfession === null || selectedProfession?.length === 0) {
        setProfessionError("Selecione ao menos uma profissão");
      } else {
        setProfessionError("");
      }

      formRef.current?.setErrors(errors);
      launchToast("Verifique o preenchimento dos dados", "error");
    }
    setLoading(false);
    return false;
  };

  const applyMask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, mask?: string) => {
      formRef.current?.setFieldValue(
        e.target.name,
        mask ? masks[mask](e.target.value) : e.target.value
      );
    },
    []
  );

  const applyMaskSring = (value: string, mask: string) => masks[mask](value);

  // const profession = (userRecivied.roles.filter((role: { id: string, name: string }) => { return role.name === 'professional' || role.name === 'admin' || role.name === 'financeiro' || role.name === 'cliente' }))
  // setSelectedProfile(profession)

  const handleActive = (tab: number) => {
    if (userId === "" || user.roles == null) {
      return;
    }
    switch (tab) {
      case 1:
        if (
          user.roles.findIndex(
            (r: any) => r.name.toLowerCase() === "cliente"
          ) >= 0
        ) {
          setActivedTab(tab);
        }
        break;
      case 2:
        if (
          user.roles.findIndex(
            (r: any) => r.name.toLowerCase() === "professional"
          ) >= 0
        ) {
          setActivedTab(tab);
        }
        break;
      default:
        setActivedTab(tab);
    }
  };

  const handleSelectProfile = (e: any) => {
    const isProfessional = e?.find(
      (role: { value: string; label: string }) =>
        role.label === "Profissional" || role.label === "professional"
    );
    if (isProfessional) {
      setSelectedProfile([...e]);
      setSelectedRoles([...e, ...selectedProfession]);
    } else {
      setSelectedProfession([]);
      if (e) {
        setSelectedProfile([...e]);
        setSelectedRoles([...e]);
      } else {
        setSelectedProfile([]);
        setSelectedRoles([]);
      }
    }
  };

  const handleSelectPrefession = (e: any) => {
    if (e) {
      setSelectedProfession(e);
      setSelectedRoles([...selectedProfile, ...e]);
    } else {
      setSelectedProfession([]);
    }
  };

  const handleCloseModal = () => {
    setSelectedProfession([]);
    setSelectedProfile([]);
    onCloseModal();
  };

  const handleDiscard = (e: any) => {
    formRef.current?.reset();
    formRef.current?.setErrors({});
    setRoleError("");
    setProfessionError("");
    setActivedTab(0);
    setSelectedRoles([]);
    setSelectedProfession([]);
    setSelectedProfile([]);
    onCloseModal();
  };

  const handleCepFind = async () => {
    try {
      const address = await cepPromise(formRef.current?.getFieldValue("cep"));

      formRef.current?.setFieldValue("city", address.city);
      formRef.current?.setFieldValue("neighborhood", address.neighborhood);
      formRef.current?.setFieldValue("service", address.service);
      formRef.current?.setFieldValue("state", address.state);
      formRef.current?.setFieldValue("street", address.street);
    } catch (error) {
      launchToast("Houve erro na solicitação do cep", "error");
    }
  };

  const normalizeLabel = (roleLabel: string) => {
    const normalizationMap: Record<string, string> = {
      cliente: "Cliente",
      professional: "Profissional",
      financeiro: "Financeiro",
      admin: "Admin",
      veterinário: "Veterinário",
      tosador: "Tosador",
      banhista: "Banhista",
    };

    return normalizationMap[roleLabel] || roleLabel;
  };

  const filterAndNormalizeRoles = (
    roles: { value: string; label: string }[]
  ) => {
    return roles.reduce((acc: { value: string; label: string }[], role) => {
      if (
        ["cliente", "professional", "financeiro", "admin"].includes(role.label)
      ) {
        acc.push({
          ...role,
          label: normalizeLabel(role.label),
        });
      }
      return acc;
    }, []);
  };

  const filterAndNormalizeProfissional = (
    roles: { value: string; label: string }[]
  ) => {
    return roles.reduce((acc: { value: string; label: string }[], role) => {
      if (["veterinário", "tosador", "banhista"].includes(role.label)) {
        acc.push({
          ...role,
          label: normalizeLabel(role.label),
        });
      }
      return acc;
    }, []);
  };

  return (
    <>
      <Modal visible={visible} closeModal={() => onCloseModal()}>
        <Styles.header>
          <Text
            text={update ? "Editar usuário" : "Criar usuário"}
            color={colors.argon.darkBlue}
            fontFamily="Open Sans"
            size={17}
            weight="600"
          />

          <Text
            text="X"
            color={colors.gray.dark01}
            fontFamily="Open Sans"
            size={17}
            weight="600"
            onTextClick={handleCloseModal}
            clickable={true}
          />
        </Styles.header>

        <div style={{ width: "100%" }}>
          <Tabs>
            <Tab
              activeTab={handleActive}
              active={activedTab === 0}
              id={0}
              title="informações do usuário"
            />
            <Tab
              activeTab={() => handleActive(1)}
              active={activedTab === 1}
              id={1}
              title="cliente"
            />
            <Tab
              activeTab={handleActive}
              active={activedTab === 2}
              id={2}
              title="profissional"
            />
          </Tabs>
          <Styles.line></Styles.line>
          <Content active={activedTab === 0}>
            <Styles.FormDiv>
              <Form onSubmit={handleSubmit} ref={formRef}>
                <div className="userInfo">
                  <Styles.row>
                    <Styles.field>
                      <Label>Nome</Label>
                      <InputRef
                        placeholder="Digite o nome"
                        name="firstName"
                        type="text"
                        disabled={!editing && update}
                      />
                    </Styles.field>
                    <Styles.field>
                      <Label>Sobrenome</Label>
                      <InputRef
                        placeholder="Digite o sobrenome"
                        name="lastName"
                        type="text"
                        disabled={!editing && update}
                      />
                    </Styles.field>

                    <Styles.field>
                      <Label>email</Label>
                      <InputRef
                        placeholder="exemplo@email.com"
                        name="email"
                        type="text"
                        disabled={!editing && update}
                      />
                    </Styles.field>
                  </Styles.row>

                  <Styles.row>
                    <Styles.field>
                      <Label>telefone celular</Label>
                      <InputRef
                        placeholder="(00) 0000-0000"
                        name="phone"
                        type="text"
                        onChange={(e) => applyMask(e, "phone")}
                        disabled={!editing && update}
                      />
                    </Styles.field>

                    <Styles.field>
                      <Label>CPF</Label>
                      <InputRef
                        placeholder="000.000.000-00"
                        name="cpf"
                        type="text"
                        onChange={(e) => applyMask(e, "cpf")}
                        disabled={!editing && update}
                      />
                    </Styles.field>
                  </Styles.row>
                  <Styles.row>
                    {" "}
                    <Styles.field>
                      <Label>data nascimento</Label>
                      <InputRef
                        name="birthday"
                        type="date"
                        disabled={!editing && update}
                      />
                    </Styles.field>
                    <Styles.field>
                      <Label>perfil</Label>
                      <Select
                        name="roles"
                        containerStyle={{ width: "33rem" }}
                        placeholder="Perfil"
                        options={filterAndNormalizeRoles(roles)}
                        isClearable={false}
                        onChange={(e) => handleSelectProfile(e)}
                        multi={true}
                        value={selectedProfile}
                        isDisabled={!editing && update}
                        error={roleError}
                      />
                    </Styles.field>
                    {selectedProfile.find(
                      (role: { value: string; label: string }) =>
                        role.label === "Profissional" ||
                        role.label === "professional"
                    ) && (
                      <Styles.field>
                        <Label>Profissão</Label>
                        <Select
                          name="professional"
                          containerStyle={{ width: "33rem" }}
                          placeholder="Profissão"
                          options={filterAndNormalizeProfissional(roles)}
                          isClearable={false}
                          multi={true}
                          onChange={(e) => handleSelectPrefession(e)}
                          value={selectedProfession}
                          isDisabled={!editing && update}
                          error={professionError}
                        />
                      </Styles.field>
                    )}
                  </Styles.row>
                </div>

                <div className="adressInfo">
                  <Styles.line></Styles.line>

                  <Styles.header>
                    <Text
                      text="ENDEREÇO PRINCIPAL"
                      color={colors.argon.textColorGray}
                      fontFamily="Open Sans"
                      size={12}
                      weight="600"
                    />
                  </Styles.header>

                  <Styles.row>
                    <Styles.field>
                      <Label>cep</Label>
                      <InputRef
                        placeholder="00000-000"
                        name="cep"
                        type="text"
                        onChange={(e) => applyMask(e, "cep")}
                        disabled={!editing && update}
                        onBlur={handleCepFind}
                      />
                    </Styles.field>

                    <Styles.field>
                      <Label>cidade</Label>
                      <InputRef
                        placeholder="Digite a cidade"
                        name="city"
                        type="text"
                        disabled={!editing && update}
                      />
                    </Styles.field>

                    <Styles.field>
                      <Label>estado</Label>
                      <InputRef
                        placeholder="Digite a sigla do estado. Ex: SP"
                        name="state"
                        type="text"
                        maxLength={2}
                        disabled={!editing && update}
                      />
                    </Styles.field>
                  </Styles.row>

                  <Styles.row>
                    <Styles.field>
                      <Label>endereço</Label>
                      <InputRef
                        placeholder="Digite o endereço"
                        name="street"
                        type="text"
                        disabled={!editing && update}
                      />
                    </Styles.field>

                    <Styles.field>
                      <Label>número</Label>
                      <InputRef
                        placeholder="ex: 100"
                        name="number"
                        type="text"
                        maxLength={10}
                        disabled={!editing && update}
                      />
                    </Styles.field>

                    <Styles.field>
                      <Label>bairro</Label>
                      <InputRef
                        placeholder="Digite o nome do bairro"
                        name="neighborhood"
                        type="text"
                        disabled={!editing && update}
                      />
                    </Styles.field>
                  </Styles.row>

                  <Styles.row>
                    <Styles.field>
                      <Label>complemento</Label>
                      <InputRef
                        placeholder="Digite o complemento. Ex: ap 201   "
                        name="complement"
                        type="text"
                        disabled={!editing && update}
                      />
                    </Styles.field>

                    <Styles.field>
                      <Label>apelido</Label>
                      <InputRef
                        placeholder="Digite o apelido do endereço. Ex: casa"
                        name="surname"
                        type="text"
                        disabled={!editing && update}
                      />
                    </Styles.field>
                  </Styles.row>
                </div>
                {!update && (
                  <Styles.rowButton margin={"70"}>
                    <Styles.WhiteButton
                      name="discard"
                      onClick={(e) => handleDiscard(e)}
                      type="button"
                    >
                      Descartar
                    </Styles.WhiteButton>
                    <Button
                      name="salve"
                      text="Salvar"
                      type="submit"
                      loading={loading}
                      styleContainer={{ minWith: "100px" }}
                    />
                  </Styles.rowButton>
                )}

                {!editing && update && (
                  <Styles.rowButton margin={"89"}>
                    <Styles.EditButton onClick={() => setEditing(true)}>
                      Editar
                    </Styles.EditButton>
                  </Styles.rowButton>
                )}

                {editing && update && (
                  <Styles.rowButton margin={"70"}>
                    <Styles.WhiteButton onClick={() => setFormFields(user)}>
                      Descartar alterações
                    </Styles.WhiteButton>
                    <Button
                      text="Salvar"
                      type="submit"
                      styleContainer={{ minWith: "100px" }}
                    />
                  </Styles.rowButton>
                )}
              </Form>
            </Styles.FormDiv>
          </Content>
          <Content active={activedTab === 1}>
            <CustommerModal
              onDiscard={handleDiscard}
              userId={userId}
              customer={user.customer}
            />
          </Content>
          <Content active={activedTab === 2}>
            <ProfessionalModal
              onDiscard={handleDiscard}
              userId={userId}
              professional={user.professional}
              editing={editing}
              roles={user.roles}
            />
          </Content>
        </div>
      </Modal>
    </>
  );
};
export default UserModal;
