import React, { useCallback, useEffect, useState } from "react";
import Pagination from "../../../components/Pagination";
import Loading from "../../../components/Loading";
import FilterModal from "./FilterModal";
import FilterList from "../../../components/FilterList";
import formatDate from "../../../shared/utils/formatDate";
import { getActualDate } from "../../../shared/utils/getActualDate";
import {
  getNewCustomers,
  getUserAdress,
} from "../../../services/user/UserService";
import masks from "../../../shared/utils/masks";
import { verifyCep } from "../../../services/api-graphql-calls";
import { launchToast } from "../../../shared/utils/launchToast";
import { headers } from "./utils/columns";
import { PanelHeader } from "components/PanelHeader";
import { Search } from "components/Search";
import Table from "components/Table";
import MainContainer from "components/MainContainer";

const actualDate = getActualDate();

const NewUsers: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isFilterModalVisible, setisFilterModalVisible] = useState(false);
  const [filters, setFilters] = useState([
    { key: "created", searchValue: actualDate },
  ] as any);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sort] = useState({ field: "created", direction: "desc" } as any);
  const [wordSearch, setWordSearch] = useState("");
  const [timer, setTimer] = useState(null as any);

  const applyMask = useCallback((value: string, mask?: string) => {
    return (value = mask ? masks[mask](value) : value);
  }, []);

  const loadCustomers = useCallback(
    async (f: any, wordSearch: string) => {
      setLoading(true);

      let where = {
        wordSearch,
      } as any;

      for (let filter of f) {
        const { key, searchValue } = filter;
        switch (key) {
          case "Bairro":
            where.neighborhood = searchValue;
            break;
          case "Data Início":
            where.from = searchValue;
            break;
          case "Data Fim":
            where.to = searchValue;
            break;
        }
      }

      try {
        let orderBy = [];
        if (!!sort.field) {
          const { field, direction } = sort;
          switch (field) {
            case "name":
              orderBy.push({ name: direction });
              break;
            case "email":
              orderBy.push({ email: direction });
              break;
            case "created_at":
              orderBy.push({ created_at: direction });
              break;
            case "zone":
              orderBy.push({ zone: direction });
              break;
            case "created":
              orderBy.push({ created_at: direction });
              break;
          }
        }

        const results = (await getNewCustomers({
          pagination: { limit, page: currentPage },
          where,
          orderBy,
        })) as any;

        const { customers, totalPages } = results;

        setTotalPage(totalPages);

        let data = [] as any;

        for (let r of customers) {
          const { id, user } = r;
          const { name, email, cpf, phone, created_at, address_main_id } = user;
          const result = (await getUserAdress(user.id)) as any;
          let onZone = false as any;
          let address = "";
          if (address_main_id !== null) {
            const mainAddress = result.AddressesCustomerByAdmin.find(
              (a: any) => a.id === address_main_id
            );

            if (mainAddress) {
              const {
                cep,
                street,
                number,
                neighborhood,
                complement,
                city,
                state,
              } = mainAddress;
              address = `${cep} - ${street}, ${number}, ${complement}, ${neighborhood}, ${city}, ${state} `;
              onZone = await verifyCep(cep);
            }
          }

          const zone = onZone ? "Dentro" : "Fora";

          data.push({
            id,
            created_at: formatDate(created_at),
            name,
            email,
            cpf: applyMask(cpf, "cpf"),
            phone: applyMask(phone, "phone"),
            address,
            zone,
          });
        }
        setData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        launchToast("Ocorreu um erro na requisição", "warning");
      }
    },
    [applyMask, currentPage, limit, sort]
  );

  useEffect(() => {
    setTimer(
      setTimeout(function () {
        loadCustomers(filters, wordSearch);
      }, 500)
    );

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [loadCustomers, wordSearch, currentPage, limit, filters]);

  const onSetPageSize = (pageSize: number) => {
    setLimit(pageSize);
  };

  const handleApplyFilter = (f: any) => {
    setFilters(f);
  };

  const handleRemoveFilter = (key: string) => {
    let newFilters = filters.filter((f: any) => f.key !== key);
    setFilters(newFilters);
    loadCustomers(newFilters, wordSearch);
  };

  return (
    <>
      {loading && <Loading />}
      <MainContainer>
        <PanelHeader
          title="Consultas"
          onClick={() => setisFilterModalVisible(true)}
        />
        <Search
          limit={limit}
          onSetPageSize={onSetPageSize}
          placeholder="Pesquisar por nome, e-mail, cpf ou telefone"
          wordSearch={wordSearch}
          setSearch={setWordSearch}
          isFilterForRegister={true}
        />
        {filters.length >= 1 && filters[0].key !== "created" && (
          <FilterList filters={filters} removeFilter={handleRemoveFilter} />
        )}
        <Table data={data} headers={headers} />
        <Pagination
          totalPage={totalPage}
          pageIndex={currentPage}
          setPage={setCurrentPage}
        />
      </MainContainer>
      <FilterModal
        visible={isFilterModalVisible}
        onCloseModal={() => setisFilterModalVisible(false)}
        applyFilters={handleApplyFilter}
        reciviedFilter={filters}
      ></FilterModal>
    </>
  );
};
export default NewUsers;
