import { gql } from "@apollo/client";
import { launchToast } from "../../shared/utils/launchToast";
import client from "../api-graphql";
import * as query from "./couponQuery";

interface IFiltersAppointments {
  pagination?: {
    limit: number;
    page: number;
  };
  order?: {
    by: string;
    direction: "ASC" | "DESC";
  }[];
  where: {
    from?: string;
    to?: string;
  };
}

const searchCoupons = async (filters?: any): Promise<any> => {
  const response = await client.query({
    query: gql`
      query Coupons($filters: FilterCuponsInputType) {
        coupons(filters: $filters) {
          totalPages
          coupons {
            id
            created_at
            name
            code
            status
            only_new_buyer
            can_accumulate
            campaign
            ends_in
            starts_in
            amount
            type
            description
            size
            category_id
            service_id
            item_id
            date_in
            date_out
            visible_to_all
          }
        }
      }
    `,
    variables: {
      filters: filters,
    },
    fetchPolicy: "no-cache",
  });
  return response;
};

const cancelCoupon = async (code: string): Promise<any[]> => {
  const response = await client.mutate({
    mutation: query.UPDATE_COUPON_STATUS,
    variables: {
      code,
      newStatus: false,
    },
  });

  return response.data;
};

const updateCoupon = async (data: any): Promise<any[]> => {
  const {
    id,
    period,
    date,
    observations,
    address_id,
    order,
    appointmentItens,
  } = data;
  const {
    increase_reason,
    discount: discount_by_admin,
    increase: increase_by_admin,
  } = order;
  try {
    const response = await client.mutate({
      mutation: query.UPDATE_COUPON_STATUS,
      variables: {
        appointment: {
          id,
          period,
          date,
          observations,
          address_id,
        },
        order: {
          id: order.id,
          discount_by_admin,
          increase_by_admin,
          increase_reason,
        },
        appointmentItens: {
          ids: appointmentItens,
        },
      },
    });

    launchToast("Agendaento atualizado com sucesso", "success");
    return response.data;
  } catch (error) {
    launchToast("Erro na requisição", "error");
  }
  return [];
};

export const insertCoupon = async (coupon?: any): Promise<any[]> => {
  const {
    name,
    code,
    size,
    only_new_buyer,
    can_accumulate,
    campaign,
    ends_in,
    starts_in,
    amount,
    type,
    description,
    category_id,
    service_id,
    item_id,
    date_in,
    date_out,
    visible_to_all,
  } = coupon;

  const response = await client.mutate({
    mutation: query.CREATE_COUPON,
    variables: {
      data: {
        name,
        code,
        size,
        status: true,
        only_new_buyer: only_new_buyer === "Sim" ? true : false,
        can_accumulate: can_accumulate === "Sim" ? true : false,
        campaign: campaign === "Sim" ? true : false,
        starts_in,
        ends_in,
        amount: parseFloat(amount),
        type: type === "%" ? "P" : "A",
        description,
        category_id,
        service_id,
        item_id,
        date_in,
        date_out,
        visible_to_all: visible_to_all === "Sim" ? true : false,
      },
    },
  });

  return response.data.createCoupon;
};

export const CoupomSegmentation = async (
  couponId: string,
  where?: any
): Promise<any[]> => {
  try {
    const response = await client.mutate({
      mutation: query.COUPOM_SEGMENTATION,
      variables: {
        couponId,
        filters: {
          where,
        },
      },
    });
    launchToast("Segmentação aplicada com sucesso", "success");

    return response.data.createCoupom;
  } catch (error) {
    launchToast("Houve erro na requisição", "error");
  }
  return [];
};

export const addCoupomToCustomer = async (
  couponId: string,
  cpf: string
): Promise<any[]> => {
  try {
    const response = await client.mutate({
      mutation: query.ADD_COUPOM_TO_CUSTOMER,
      variables: {
        couponId,
        cpf,
      },
    });
    launchToast("Cupom adicionado para o cliente com sucesso", "success");

    return response.data.createCoupom;
  } catch (errors: any) {
    launchToast(errors.toString(), "error");
  }
  return [];
};

export const removeCustomer = async (
  couponId: string,
  cpf: string
): Promise<any[]> => {
  try {
    const response = await client.mutate({
      mutation: query.REMOVE_CUSTOMER,
      variables: {
        couponId,
        cpf,
      },
    });
    launchToast("Cupom retirado do cliente com sucesso", "success");

    return response.data.removeCoupomToCustomersByCpf;
  } catch (errors: any) {
    launchToast(errors.toString(), "error");
  }
  return [];
};

export const getCustomers = async (coupon_id: string): Promise<any[]> => {
  const response = await client.query({
    query: gql`
    {
      customerCoupons(coupon_id: ${JSON.stringify(coupon_id)}){
        user {
          name
          cpf
        }
      } 
    }
  `,
    fetchPolicy: "no-cache",
  });
  return response.data.customerCoupons;
};
export { searchCoupons, cancelCoupon, updateCoupon };
