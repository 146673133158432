import { format, parseISO } from "date-fns";
import React from "react";
import convertToCurrencyFormat from "../../../../shared/utils/convertToCurrencyFormat";

import * as Styles from "./styles";
import { ITotalResumePricesProps } from "../types";

const AppointmentsFinancialResumePrices: React.FC<{
  data: ITotalResumePricesProps;
}> = ({ data }) => (
  <Styles.TotalCardContainer>
    <Styles.Header>Total exibido</Styles.Header>
    <Styles.Body>
      <Styles.Row>{`Total: ${convertToCurrencyFormat(data.total)}`}</Styles.Row>
    </Styles.Body>
    <Styles.Header>Total por dia</Styles.Header>
    <Styles.Body>
      {data.details_by_date.map((detail_date) => (
        <Styles.Row>{`${format(
          parseISO(detail_date.date),
          "dd/MM/yyyy"
        )}: ${convertToCurrencyFormat(detail_date.total)}`}</Styles.Row>
      ))}
    </Styles.Body>
    <Styles.Header>Total por profissional</Styles.Header>
    <Styles.Body>
      {data.details_by_professional.map((detail_professional) => (
        <Styles.Row>{`${
          detail_professional.professional_name
        }: ${convertToCurrencyFormat(detail_professional.total)}`}</Styles.Row>
      ))}
    </Styles.Body>
  </Styles.TotalCardContainer>
);

export default AppointmentsFinancialResumePrices;
