import { addDays, addMinutes, format } from "date-fns";
import React, { useState } from "react";

import Loading from "../../../components/Loading";
import Text from "../../../components/Text";

import colors from "../../../shared/utils/constants/colors";
import { launchToast } from "../../../shared/utils/launchToast";
import processError from "../../../shared/utils/processError";

import ExcelJS from "exceljs";
import PowerSupplyEnum from "../../../shared/enums/PowerSupplyEnum";
import calculateDistanceBetweenHours from "../../../shared/utils/calculateDistanceBetweenHours";
import { calculateSizeByWeigthPet } from "../../../shared/utils/calculateSizeByWeigthPet";
import convertToCurrencyFormat from "../../../shared/utils/convertToCurrencyFormat";
import AppointmentsReportTable from "./AppointmentsReportTable";
import AppointmentsResumePrices from "./AppointmentsResumePrices";
import * as Styles from "./styles";

import { useDisclosure } from "@chakra-ui/hooks";
import { useAuth } from "../../../hooks/Auth";
import AppointmentStatusEnum from "../../../shared/enums/AppointmentStatusEnum";
import PlatformEnum from "../../../shared/enums/PlatformEnum";
import { IAppointmentFiltersFormData } from "./forms/AppointmentFiltersForm/types";
import { loadAppointmentsByRange, loadReportResumePrices } from "./graphQL";
import AppointmentFiltersModal from "./modals/AppointmentFiltersModal";
import { IAppointmentReport, IExcelExport, ITotalResumePricesProps } from "./types";
import Pagination from "components/Pagination";

const formatEndTime = (time: any, executionTimeInMinutes: any) => {
  if (!time || !executionTimeInMinutes || isNaN(executionTimeInMinutes)) {
    return 'no record';
  }

  const [hoursStr, minutesStr] = time.split(":");
  const hours: number = parseInt(hoursStr);
  const minutes: number = parseInt(minutesStr);

  const correctedMinutes: string = minutes.toString().padStart(2, "0");
  const correctedHours: string = hours.toString().padStart(2, '0');

  const appointment = new Date(`1970-01-01T${correctedHours}:${correctedMinutes}`);
  const result = addMinutes(appointment, executionTimeInMinutes);

  return `${result.getHours()}:${result.getMinutes()}`;
};

enum TransactionTaypeEnum {
  definir = 0,
  cartão = 1,
  link = 2,
  pix = 3,
  transferencia = 4,
  dinheiro = 5,
  newLink = 6,
  linkavulso = 7,
  reembolso = 8,
};

const AppointmentsReport: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [selectedTabOption, setSelectedTabOption] = useState<
    "table" | "totals"
  >("table");
  const [appointmentsFormatted, setAppointmentsFormatted] = useState<
    IAppointmentReport[] | [] | null
  >(null);
  const [selectedFilters, setSelectedFilters] =
    useState<IAppointmentFiltersFormData | null>(null);
  const [totalResumePrices, setTotalResumePrices] = useState<
    ITotalResumePricesProps | undefined
  >(undefined);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [page, setPage] = useState(1);

  const [exportingExcel, setExportingExcel] = useState(false);

  const handleSearchAppointmentsByDateRange = async ({
    start_date,
    end_date,
    categories,
    status,
    tutor_name
  }: IAppointmentFiltersFormData) => {
    try {
      setPage(1);
      setLoading(true);

      const { appointments, appointmentsResumePricesReports } =
        await loadReportResumePrices({
          filters: {
            start_date,
            end_date,
            categories,
            status,
            tutor_name,
          },
          pagination: true,
        });

      setNumberOfPages(appointments.totalPages);
      setSelectedFilters({
        start_date,
        end_date,
        categories,
        status,
        tutor_name,
      });
      setTotalResumePrices(appointmentsResumePricesReports);
      
      setAppointmentsFormatted(
        appointments.appointments.map((appointment) => {
          const serviceTotalPrice = Number(appointment.service_price / 100);
          const additionalsTotalPrice = appointment.appointments_items.reduce(
            (total, additional) => total + Number(additional.item_price),
            0
          );
          const appointmentTotalPrice = serviceTotalPrice + additionalsTotalPrice;
          let servicePaidPrice = 0;
          let additionalsPaidPrice = 0;
          const needToCalculate = Number(appointment.order.discount_by_admin) > 0 && (appointment.service_price / 100) === Number(appointment.paid_price);
          if (needToCalculate) {
            const totalOrderPrice = Number(appointment.order.total_discount) + Number(appointment.order.paid_price);
            const discountPercentage = Number(appointment.order.total_discount) / totalOrderPrice;
            const discountPerService = serviceTotalPrice * discountPercentage;
            const discountPerAdditional = additionalsTotalPrice * discountPercentage;

            servicePaidPrice = serviceTotalPrice - discountPerService;
            additionalsPaidPrice = additionalsTotalPrice - discountPerAdditional;
          } else {
            servicePaidPrice = Number(appointment.paid_price);
            additionalsPaidPrice = appointment.appointments_items.reduce(
              (total, additional) => total + Number(additional.paid_price),
              0
            );
          }

          const appointmentPaidPrice = servicePaidPrice + additionalsPaidPrice;

          let payment = "";

          if (appointment.status >= AppointmentStatusEnum.Pago) {
            payment = "CONCLUIDO";
          } else if (
            appointment.status === AppointmentStatusEnum["Aguardando pagamento"]
          ) {
            payment = "AGUARDANDO";
          } else if (
            appointment.status ===
            AppointmentStatusEnum["Aguardando Pagamento Adicional"]
          ) {
            payment = "AGUARDANDO PAGAMENTO ADICIONAL";
          }

          let platform = "sem registro";

          if (appointment.order.platform === PlatformEnum.mobile) {
            platform = "cliente";
          } else if (appointment.order.platform === PlatformEnum.admin) {
            platform = "admin";
          } else if (appointment.order.platform === PlatformEnum.job) {
            platform = "automatico";
          } else if (appointment.order.platform === PlatformEnum.landpage) {
            platform = "landpage";
          } else if (appointment.order.platform === PlatformEnum.auto_renew) {
            platform = "renovacao automática";
          }
          return {
            period: appointment.period.toString() || "sem registro",
            observations: appointment.observations || "",
            deslocation_time:
              !!appointment.on_the_way_status_hour &&
                !!appointment.on_local_status_hour
                ? calculateDistanceBetweenHours(
                  appointment.on_the_way_status_hour,
                  appointment.on_local_status_hour
                ).toString()
                : "",
            difference_time:
              appointment.on_the_way_status_hour &&
                appointment.service_completed_status_hour
                ? calculateDistanceBetweenHours(
                  appointment.on_the_way_status_hour,
                  appointment.service_completed_status_hour
                ).toString()
                : "sem registro",
            start_time: appointment.hour || "sem registro",
            end_time:formatEndTime(appointment.hour, appointment.execution_time)   ,
            real_start_time: appointment.on_the_way_status_hour || "",
            real_end_time: appointment.service_completed_status_hour || "",
            customer_name: appointment.customer.user.name || "sem registro",
            customer_cpf: appointment.customer.user.cpf || "sem registro",
            pet_name: appointment.pet.name || "sem registro",
            pet_breed: appointment.pet.breed?.name || "sem registro",
            pet_gender: appointment.pet.gender || "sem registro",
            pet_specie: appointment.pet.specie.name || "sem registro",
            pet_size: appointment.pet?.breed?.size
              ? appointment.pet?.breed?.size
              : calculateSizeByWeigthPet(
                appointment.pet.specie.name,
                appointment.pet.weight || 0
              ) || "sem registro",
            cep: appointment.address.cep,
            address_street: appointment.address.street || "sem registro",
            address_neighborhood:
              appointment.address.neighborhood || "sem registro",
            address_number: appointment.address.number || "sem registro",
            service_name: appointment.service.name || "sem registro",
            service_full_price: convertToCurrencyFormat(serviceTotalPrice),
            additionals:
              appointment.appointments_items
                .map((additional) => additional.item.name)
                .toString() || "sem registro",
            additionals_full_price: convertToCurrencyFormat(
              additionalsTotalPrice.toFixed(2)
            ),
            discount: convertToCurrencyFormat(
              (appointmentTotalPrice - appointmentPaidPrice).toFixed(2)
            ),
            coupon_relation:
              appointment.order.coupon_relation?.code || "Sem cupom",
            paided_price: convertToCurrencyFormat(appointmentPaidPrice.toFixed(2)),
            payment: payment,
            observations_admin: "",
            power_supply: appointment.power_supply
              ? PowerSupplyEnum[appointment.power_supply]
              : "sem registro",
            professional: {
              id: appointment.professional?.id,
              name: appointment.professional?.user.name,
            },
            platform: platform,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      const { message } = processError(error, "GRAPHQL");

      setLoading(false);
      launchToast(message, "error");
    }
  };

  const handleExportFullData = async () => {
    if (!selectedFilters?.start_date || !selectedFilters?.end_date) {
      return;
    }

    setExportingExcel(true);

    try {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = user.name;
      workbook.lastModifiedBy = user.name;
      workbook.created = new Date();
      workbook.modified = new Date();
      workbook.lastPrinted = new Date();
      workbook.views = [
        {
          x: 0,
          y: 0,
          width: 10000,
          height: 20000,
          firstSheet: 0,
          activeTab: 1,
          visibility: "visible",
        },
      ];

      const range_of_dates = [];
      let current_date = selectedFilters.start_date;

      while (current_date <= selectedFilters.end_date) {
        const next_day = addDays(new Date(current_date), 1);
        range_of_dates.push(current_date);
        current_date = next_day.toISOString().split("T")[0];
      }

      for (const date of range_of_dates) {
        const { appointments, appointmentsResumePricesReports } =
          await loadReportResumePrices({
            filters: {
              ...selectedFilters,
              start_date: date,
              end_date: date,
            },
            pagination: false,
          });

        const data_formatted = appointments.appointments.map((appointment) => {
          const serviceTotalPrice = Number(appointment.service_price / 100);
          const additionalsTotalPrice = appointment.appointments_items.reduce(
            (total, additional) => total + Number(additional.item_price),
            0
          );
          const appointmentTotalPrice = serviceTotalPrice + additionalsTotalPrice;
          let servicePaidPrice = 0;
          let additionalsPaidPrice = 0;
          const needToCalculate = Number(appointment.order.discount_by_admin) > 0 && (appointment.service_price / 100) === Number(appointment.paid_price);
          if (needToCalculate) {
            const totalOrderPrice = Number(appointment.order.total_discount) + Number(appointment.order.paid_price);
            const discountPercentage = Number(appointment.order.total_discount) / totalOrderPrice;
            const discountPerService = serviceTotalPrice * discountPercentage;
            const discountPerAdditional = additionalsTotalPrice * discountPercentage;

            servicePaidPrice = serviceTotalPrice - discountPerService;
            additionalsPaidPrice = additionalsTotalPrice - discountPerAdditional;
          } else {
            servicePaidPrice = Number(appointment.paid_price);
            additionalsPaidPrice = appointment.appointments_items.reduce(
              (total, additional) => total + Number(additional.paid_price),
              0
            );
          }

          const appointmentPaidPrice = servicePaidPrice + additionalsPaidPrice;

          let payment = "";

          if (appointment.status >= AppointmentStatusEnum.Pago) {
            payment = "CONCLUIDO";
          } else if (
            appointment.status === AppointmentStatusEnum["Aguardando pagamento"]
          ) {
            payment = "AGUARDANDO";
          } else if (
            appointment.status ===
            AppointmentStatusEnum["Aguardando Pagamento Adicional"]
          ) {
            payment = "AGUARDANDO PAGAMENTO ADICIONAL";
          }

          let platform = "sem registro";

          if (appointment.order.platform === PlatformEnum.mobile) {
            platform = "cliente";
          } else if (appointment.order.platform === PlatformEnum.admin) {
            platform = "admin";
          } else if (appointment.order.platform === PlatformEnum.job) {
            platform = "automatico";
          } else if (appointment.order.platform === PlatformEnum.landpage) {
            platform = "landpage";
          // }else if (appointment.order.platform === PlatformEnum.auto_renew) {
            // platform = "renovação automática";
          }
          return {
            period: appointment.period.toString() || "sem registro",
            observations: appointment.observations || "",
            deslocation_time:
              !!appointment.on_the_way_status_hour &&
                !!appointment.on_local_status_hour
                ? calculateDistanceBetweenHours(
                  appointment.on_the_way_status_hour,
                  appointment.on_local_status_hour
                ).toString()
                : "",
            difference_time:
              appointment.on_the_way_status_hour &&
                appointment.service_completed_status_hour
                ? calculateDistanceBetweenHours(
                  appointment.on_the_way_status_hour,
                  appointment.service_completed_status_hour
                ).toString()
                : "sem registro",
            start_time: appointment.hour || "sem registro",
            end_time:
              appointment.service_completed_status_hour || "sem registro",
            real_start_time: appointment.on_the_way_status_hour || "",
            real_end_time: appointment.service_completed_status_hour || "",
            customer_name: appointment.customer.user.name || "sem registro",
            customer_cpf: appointment.customer.user.cpf || "sem registro",
            pet_name: appointment.pet.name || "sem registro",
            pet_breed: appointment.pet.breed?.name || "sem registro",
            pet_gender: appointment.pet.gender || "sem registro",
            pet_specie: appointment.pet.specie.name || "sem registro",
            pet_size: appointment.pet?.breed?.size
              ? appointment.pet?.breed?.size
              : calculateSizeByWeigthPet(
                appointment.pet.specie.name,
                appointment.pet.weight || 0
              ) || "sem registro",
            cep: appointment.address.cep,
            address_street: appointment.address.street || "sem registro",
            address_neighborhood:
              appointment.address.neighborhood || "sem registro",
            address_number: appointment.address.number || "sem registro",
            address_complement:
              appointment.address.complement || "sem registro",
            service_name: appointment.service.name || "sem registro",
            pack_frequency: appointment.pack_frequency?.toLowerCase() || "sem registro",
            service_full_price: serviceTotalPrice,
            additionals:
              appointment.appointments_items
                .map((additional) => additional.item.name)
                .toString() || "sem registro",
            additionals_full_price: additionalsTotalPrice.toFixed(2),
            discount: (appointmentTotalPrice - appointmentPaidPrice).toFixed(2),
            coupon_relation:
              appointment.order.coupon_relation?.code || "Sem cupom",
            paided_price: appointmentPaidPrice.toFixed(2),
            payment: payment,
            observations_admin: "",
            platform: platform,
            power_supply: appointment.power_supply
              ? PowerSupplyEnum[appointment.power_supply]
              : "sem registro",
            professional: {
              id: appointment.professional?.id,
              name: appointment.professional?.user.name,
            },
            gerado_em: String(appointment.created_at),
            total_paided_order: appointment.order.paid_price,
            transaction_type: appointment.order.transactions
            .map((t) => TransactionTaypeEnum[t.type])
            .toString(),
            transaction_id: appointment.order.transactions
            .map((t) => t.id)
            .toString(),
            pagarme_transaction_id: appointment.order.transactions
              .map((t) => t.transaction_pagseguro_id)
              .toString(),
            provider: appointment.order.transactions
              .map((t) => t.provider).toString(),
          };
        });

        const separeted_by_professional = data_formatted.reduce<IExcelExport[]>(
          (result, row) => {
            const result_index = result.findIndex(
              (r) => r.professional.id === row.professional?.id
            );
            if (result_index >= 0) {
              result[result_index].appointments.push(row as any);
            } else {
              result.push({
                professional: row.professional.id
                  ? row.professional
                  : {
                    id: undefined,
                    name: "sem professional",
                  },
                appointments: [row as any],
              });
            }

            return result;
          },
          []
        );

        const new_sheet = workbook.addWorksheet(date);

        const row_full_price = new_sheet.addRow([
          "FATURAMENTO",
          convertToCurrencyFormat(appointmentsResumePricesReports.total),
        ]);
        row_full_price.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "8EB7AA" },
          bgColor: { argb: "8EB7AA" },
        };
        row_full_price.height = 32;
        row_full_price.alignment = { vertical: "middle" };

        new_sheet.addRow([]);
        const header = new_sheet.addRow([
          "PERÍODO",
          "OBS.",
          "DESL.",
          "TEMPO",
          "INÍCIO",
          "FIM",
          "INÍCIO REAL",
          "FIM REAL",
          "TUTOR",
          "CPF",
          "PET",
          "RAÇA",
          "SEXO",
          "ESPÉCIE",
          "PORTE",
          "CEP",
          "ENDEREÇO",
          "BAIRRO",
          "NUMERO",
          "COMPLEMENT",
          "SERVIÇO",
          "PACOTE",
          "ADICIONAIS",
          "VALOR DO SERVICO",
          "VALOR DO ADICIONAL",
          "DESCONTO",
          "CUPOM",
          "VALOR FINAL",
          "PAGAMENTO",
          "OBSERVAÇÕES",
          "GERADOR - TOMADA",
          "CRIADO PELO",
          "GERADO EM",
          "VALOR PEDIDO",
          "TIPO DE TRANSAÇÃO",
          "ID DA TRANSAÇÃO",
          "PAGARME IDS",
          "PROVEDOR",
        ]);

        header.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "8EB7AA" },
          bgColor: { argb: "8EB7AA" },
        };
        header.border = {
          top: { style: "thin", color: { argb: "00000" } },
          left: { style: "thin", color: { argb: "00000" } },
          bottom: { style: "thin", color: { argb: "00000" } },
          right: { style: "thin", color: { argb: "00000" } },
        };
        header.font = { color: { argb: "FFFFF" } };
        header.height = 32;
        header.alignment = { vertical: "middle" };

        separeted_by_professional.forEach((group) => {
          const header_professional = new_sheet.addRow([
            group.professional.name,
          ]);
          header_professional.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "BFBFC0" },
            bgColor: { argb: "BFBFC0" },
          };
          group.appointments.forEach((new_row) => {
            new_sheet.addRow([
              new_row.period,
              new_row.observations,
              new_row.deslocation_time,
              new_row.difference_time,
              new_row.start_time,
              new_row.end_time,
              new_row.real_start_time,
              new_row.real_end_time,
              new_row.customer_name,
              new_row.customer_cpf,
              new_row.pet_name,
              new_row.pet_breed,
              new_row.pet_gender,
              new_row.pet_specie,
              new_row.pet_size,
              new_row.cep,
              new_row.address_street,
              new_row.address_neighborhood,
              new_row.address_number,
              new_row.address_complement,
              new_row.service_name,
              new_row.pack_frequency,
              new_row.additionals,
              new_row.service_full_price,
              new_row.additionals_full_price,
              new_row.discount,
              new_row.coupon_relation,
              new_row.paided_price,
              new_row.payment,
              new_row.observations_admin,
              new_row.power_supply,
              new_row.platform,
              new_row.gerado_em,
              new_row.total_paided_order,
              new_row.transaction_type,
              new_row.transaction_id,
              new_row.pagarme_transaction_id,
              new_row.provider
            ]);
          });
          new_sheet.addRow([]);
        });
      }

      const writeFile = (newFileName: any, content: any) => {
        const link = document.createElement("a");

        const blob = new Blob([content], {
          type: "application/vnd.ms-excel;charset=utf-8;",
        });

        link.download = `${newFileName}.xlsx`;
        link.href = URL.createObjectURL(blob);
        link.click();
      };

      const sheetName = `relatorio de agendamentos _ ${selectedFilters.start_date} ate ${selectedFilters.end_date}`;

      workbook.xlsx.writeBuffer().then((buffer) => {
        writeFile(sheetName, buffer);
      });
    } catch (error) {
      const { message } = processError(error, "GRAPHQL");
      launchToast(message, "error");
    } finally {
      setExportingExcel(false);
    }
  };

  const onPageChange = async (new_page: number) => {
    if (!selectedFilters?.start_date || !selectedFilters?.end_date) {
      return;
    }
    setPage(new_page);

    setLoading(true);

    try {
      const results = await loadAppointmentsByRange({
        filters: selectedFilters,
        page: new_page,
      });
      setAppointmentsFormatted(
        results.appointments.map((appointment) => {
          const serviceTotalPrice = Number(appointment.service_price / 100);
          const additionalsTotalPrice = appointment.appointments_items.reduce(
            (total, additional) => total + Number(additional.item_price),
            0
          );
          const appointmentTotalPrice = serviceTotalPrice + additionalsTotalPrice;
          let servicePaidPrice = 0;
          let additionalsPaidPrice = 0;
          const needToCalculate = Number(appointment.order.discount_by_admin) > 0 && (appointment.service_price / 100) === Number(appointment.paid_price);
          if (needToCalculate) {
            const totalOrderPrice = Number(appointment.order.total_discount) + Number(appointment.order.paid_price);
            const discountPercentage = Number(appointment.order.total_discount) / totalOrderPrice;
            const discountPerService = serviceTotalPrice * discountPercentage;
            const discountPerAdditional = additionalsTotalPrice * discountPercentage;

            servicePaidPrice = serviceTotalPrice - discountPerService;
            additionalsPaidPrice = additionalsTotalPrice - discountPerAdditional;
          } else {
            servicePaidPrice = Number(appointment.paid_price);
            additionalsPaidPrice = appointment.appointments_items.reduce(
              (total, additional) => total + Number(additional.paid_price),
              0
            );
          }

          const appointmentPaidPrice = servicePaidPrice + additionalsPaidPrice;

          let payment = "";

          if (appointment.status >= AppointmentStatusEnum.Pago) {
            payment = "CONCLUIDO";
          } else if (
            appointment.status === AppointmentStatusEnum["Aguardando pagamento"]
          ) {
            payment = "AGUARDANDO";
          } else if (
            appointment.status ===
            AppointmentStatusEnum["Aguardando Pagamento Adicional"]
          ) {
            payment = "AGUARDANDO PAGAMENTO ADICIONAL";
          }

          let platform = "sem registro";

          if (appointment.order.platform === PlatformEnum.mobile) {
            platform = "cliente";
          } else if (appointment.order.platform === PlatformEnum.admin) {
            platform = "admin";
          } else if (appointment.order.platform === PlatformEnum.job) {
            platform = "automatico";
          } else if (appointment.order.platform === PlatformEnum.landpage) {
            platform = "landpage";
          // } 
          // else if (appointment.order.platform === PlatformEnum.auto_renew) {
            // platform = "renovacao automática";
          }

          return {
            period: appointment.period.toString() || "sem registro",
            observations: appointment.observations || "",
            deslocation_time:
              !!appointment.on_the_way_status_hour &&
                !!appointment.on_local_status_hour
                ? calculateDistanceBetweenHours(
                  appointment.on_the_way_status_hour,
                  appointment.on_local_status_hour
                ).toString()
                : "",
            difference_time:
              appointment.on_the_way_status_hour &&
                appointment.service_completed_status_hour
                ? calculateDistanceBetweenHours(
                  appointment.on_the_way_status_hour,
                  appointment.service_completed_status_hour
                ).toString()
                : "sem registro",
            start_time: appointment.hour || "sem registro",
            end_time:
              appointment.service_completed_status_hour || "sem registro",
            real_start_time: appointment.on_the_way_status_hour || "",
            real_end_time: appointment.service_completed_status_hour || "",
            customer_name: appointment.customer.user.name || "sem registro",
            customer_cpf: appointment.customer.user.cpf || "sem registro",
            pet_name: appointment.pet.name || "sem registro",
            pet_breed: appointment.pet.breed?.name || "sem registro",
            pet_gender: appointment.pet.gender || "sem registro",
            pet_specie: appointment.pet.specie.name || "sem registro",
            pet_size: appointment.pet?.breed?.size
              ? appointment.pet?.breed?.size
              : calculateSizeByWeigthPet(
                appointment.pet.specie.name,
                appointment.pet.weight || 0
              ) || "sem registro",
            cep: appointment.address.cep,
            address_street: appointment.address.street || "sem registro",
            address_neighborhood:
              appointment.address.neighborhood || "sem registro",
            address_number: appointment.address.number || "sem registro",
            service_name: appointment.service.name || "sem registro",
            service_full_price: convertToCurrencyFormat(serviceTotalPrice),
            additionals:
              appointment.appointments_items
                .map((additional) => additional.item.name)
                .toString() || "sem registro",
            additionals_full_price: convertToCurrencyFormat(
              additionalsTotalPrice.toFixed(2)
            ),
            discount: convertToCurrencyFormat(
              (appointmentTotalPrice - appointmentPaidPrice).toFixed(2)
            ),
            coupon_relation:
              appointment.order.coupon_relation?.code || "Sem cupom",
            paided_price: convertToCurrencyFormat(appointmentPaidPrice.toFixed(2)),
            payment: payment,
            observations_admin: "",
            power_supply: appointment.power_supply
              ? PowerSupplyEnum[appointment.power_supply]
              : "sem registro",
            professional: {
              id: appointment.professional?.id,
              name: appointment.professional?.user.name,
            },
            platform: platform,
          };
        })
      );
    } catch (error) {
      setPage(new_page - 1);
      const { message } = processError(error, "GRAPHQL");
      launchToast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const AppointmentFiltersModalControl = useDisclosure();

  return (
    <>
      {loading && <Loading />}

      <Styles.BackgroundPanel>
        <Styles.PanelHeader>
          <Styles.PanelItens>
            <Text
              text="Relatórios"
              color={colors.gray.white}
              fontFamily="Open Sans"
              size={20}
              weight="600"
              marginRight={4}
              marginTop={0}
            />
          </Styles.PanelItens>

          <Styles.WhiteButton onClick={AppointmentFiltersModalControl.onOpen}>
            Buscar
          </Styles.WhiteButton>
        </Styles.PanelHeader>

        <Styles.TablePanel>
          <Styles.FilterDisplay>
            <div>
              <Text
                text="Agendamentos"
                color={colors.argon.textColorDark}
                fontFamily="Open Sans"
                size={17}
                weight="600"
                marginRight={4}
                marginTop={0}
                align="left"
              />
            </div>

            <Styles.TabHeaderRow>
              <Styles.Option
                onClick={() => setSelectedTabOption("table")}
                selected={selectedTabOption === "table"}
              >
                Tabela
              </Styles.Option>

              <Styles.Option
                onClick={() => setSelectedTabOption("totals")}
                selected={selectedTabOption === "totals"}
              >
                Totais
              </Styles.Option>
            </Styles.TabHeaderRow>
          </Styles.FilterDisplay>

          {selectedTabOption === "table" && (
            <Styles.Content>
              <Styles.ContentHeaderDateInfo>
                <Text
                  text={
                    selectedFilters
                      ? `Listagem do dia ${format(
                          addDays(new Date(selectedFilters.start_date), 1),
                          "dd/MM/yyyy"
                        )} até ${format(
                          addDays(new Date(selectedFilters.end_date), 1),
                          "dd/MM/yyyy"
                        )}`
                      : "Selecione as datas no botão (Buscar) para listar os agendamentos"
                  }
                  color={colors.gray.dark01}
                  fontFamily="Open Sans"
                  size={14}
                  align="left"
                  weight="400"
                />
              </Styles.ContentHeaderDateInfo>

              {appointmentsFormatted && (appointmentsFormatted?.length > 0
              ? (
                <>
                  <Styles.ContentTable>
                    <AppointmentsReportTable
                      data={appointmentsFormatted}
                    />
                  </Styles.ContentTable>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                      marginBottom: "16px",
                    }}
                  >
                    <Styles.ButtonExport
                      onClick={() => handleExportFullData()}
                      disabled={exportingExcel}
                    >
                      Exportar dados
                    </Styles.ButtonExport>
                    {exportingExcel && <p>exportando...</p>}
                    <Pagination
                      totalPage={numberOfPages}
                      canPreviousPage={page > 1}
                      previousPage={() => {
                        page > 1 && onPageChange(page - 1)}}
                      nextPage={() => {
                        page < numberOfPages && onPageChange(page + 1)}}
                      canNextPage={page < numberOfPages}
                      pageIndex={page}
                      setPage={onPageChange}
                    />
                  </div>
                </>
              ) : (
                <Text
                  text="Sua busca não retornou nenhum resultado"
                  color={colors.gray.dark01}
                  fontFamily="Open Sans"
                  size={16}
                  align="left"
                  weight="400"
                />
              ))}
            </Styles.Content>
          )}
          {selectedTabOption === "totals" && (
            <Styles.Content>
              {!!totalResumePrices ? (
                <AppointmentsResumePrices data={totalResumePrices} />
              ) : (
                <Styles.ContentHeaderDateInfo>
                  <Text
                    text={
                      selectedFilters
                        ? `Listagem do dia ${format(
                            addDays(new Date(selectedFilters.start_date), 1),
                            "dd/MM/yyyy"
                          )} até ${format(
                            addDays(new Date(selectedFilters.end_date), 1),
                            "dd/MM/yyyy"
                          )}`
                        : "Selecione as datas no botão (Buscar) para listar os agendamentos"
                    }
                    color={colors.gray.dark01}
                    fontFamily="Open Sans"
                    size={14}
                    align="left"
                    weight="400"
                  />
                </Styles.ContentHeaderDateInfo>
              )}
            </Styles.Content>
          )}
        </Styles.TablePanel>
      </Styles.BackgroundPanel>

      <AppointmentFiltersModal
        onClose={AppointmentFiltersModalControl.onClose}
        isOpen={AppointmentFiltersModalControl.isOpen}
        onSubmit={handleSearchAppointmentsByDateRange}
      />
    </>
  );
};

export default AppointmentsReport;
