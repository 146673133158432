import React, { useEffect, useState } from "react";
import colors from "../../shared/utils/constants/colors";
import * as Styles from "./styles";

import ExcelJS from "exceljs";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { Label } from "../../components/Label/styles";
import Spinner from "../../components/Spinner";
import Text from "../../components/Text";
import { useAuth } from "../../hooks/Auth";
import AppointmentStatusEnum from "../../shared/enums/AppointmentStatusEnum";
import PlatformEnum from "../../shared/enums/PlatformEnum";
import PowerSupplyEnum from "../../shared/enums/PowerSupplyEnum";
import calculateDistanceBetweenHours from "../../shared/utils/calculateDistanceBetweenHours";
import { calculateSizeByWeigthPet } from "../../shared/utils/calculateSizeByWeigthPet";
import convertToCurrencyFormat from "../../shared/utils/convertToCurrencyFormat";
import { launchToast } from "../../shared/utils/launchToast";
import processError from "../../shared/utils/processError";
import IAppointment from "../../types/IAppointment";
import IProfessional from "../../types/IProfessional";
import IRoute from "../../types/IRoute";
import ITruck from "../../types/ITruck";
import {
  appointmentsRouted,
  cancelRouteByDate,
  createnewRoute,
  getLastRouteCreatedByDate,
  getProfessionalsActived,
  loadAppointemtnsToExport,
  verifyRouteVupptStatus,
} from "./graphQL";
import ScheduleBoard from "./ScheduleBoard";

interface IProfessionalCustom extends IProfessional {
  checked: boolean;
}

interface IRouteCustom extends IRoute {
  status_formatted: string;
  professionals_ids: string[];
}

interface IAppointmentByProfessionals {
  professional: {
    id?: string;
    name: string;
    changes_pending: boolean;
    truck?: ITruck;
  };
  appointments: IAppointment[];
}

export type IExcelExport = {
  professional: {
    id?: string;
    name?: string;
  };
  appointments: {
    period: string;
    observations: string;
    deslocation_time: string;
    difference_time: string;
    start_time: string;
    end_time: string;
    real_start_time: string;
    real_end_time: string;
    customer_name: string;
    pet_name: string;
    pet_breed: string;
    pet_gender: string;
    pet_specie: string;
    pet_size: string;
    cep: string;
    address_street: string;
    address_neighborhood: string;
    address_number: string;
    address_complement: string;
    service_name: string;
    service_full_price: number;
    additionals: string;
    additionals_full_price: number;
    discount: number;
    paided_price: number;
    payment: string;
    observations_admin: string;
    power_supply: string;
    platform: string;
    professional: {
      id?: string;
      name?: string;
    };
    gerado_em: string;
    total_paided_order: number;
    pagarme_transaction_id: string;
  }[];
};

const RouteDashboard: React.FC = () => {
  const { user } = useAuth();
  const [professionals, setProfessionals] = useState<IProfessionalCustom[]>([]);
  const [appointmentsByProfessionals, setAppointmentsByProfessionals] =
    useState<IAppointmentByProfessionals[]>([]);
  const [lastRoute, setLastRoute] = useState<IRouteCustom | undefined>(
    undefined
  );
  const [date, setDate] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingVerifyRouteStatus, setLoadingVerifyRouteStatus] =
    useState(false);
  const [loadingAppointments, setLoadingAppointments] = useState(false);

  const handleCreateNewRoute = async (type: "MANUAL" | "AUTOMATICO") => {
    if (!date) {
      launchToast("Erro na data da rota", "error");
      return;
    }
    const professionals_selected = professionals.filter((p) => p.checked);
    const professionals_ids = professionals_selected.reduce<string[]>(
      (ids, professional) => {
        if (professional.checked) {
          if (!!professional.truck?.id) {
            ids.push(professional.id);
          }
        }
        return ids;
      },
      []
    );
    if (professionals_ids.length !== professionals_selected.length) {
      launchToast("Algum profissional selecionado esta sem truck", "error");
      return;
    }
    if (professionals_ids.length === 0) {
      launchToast("Escolha pelo menos 1 truck", "error");
      return;
    }

    try {
      setLoading(true);
      const new_route = await createnewRoute({
        date: date,
        professionals_ids: professionals_ids,
        type: type,
      });
      formatLastRoute(new_route);
      launchToast(
        "Rota criada com sucesso, aguarde o processamento",
        "success"
      );
    } catch (error) {
      const { message } = processError(error, "GRAPHQL");
      launchToast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyStatusRoute = () => {
    if (!lastRoute) {
      launchToast("Dados inconsistentes", "error");
      return;
    }
    setLoadingVerifyRouteStatus(true);
    verifyRouteVupptStatus(lastRoute.vuupt_id)
      .then((lastRouteRecovered) => {
        if (!!lastRouteRecovered) {
          formatLastRoute(lastRouteRecovered);
        }
      })
      .catch((error) => {
        const { message } = processError(error, "GRAPHQL");
        launchToast(message, "error");
      })
      .finally(() => {
        setLoadingVerifyRouteStatus(false);
      });
  };

  const handleDiscardRoute = async () => {
    if (!date) {
      launchToast("Erro na data da rota", "error");
      return;
    }

    try {
      await cancelRouteByDate(date);
      getProfessionalsActived()
        .then((professionalsRecovered) => {
          setProfessionals(
            professionalsRecovered
              .sort((a, b) => {
                if (a.user.name < b.user.name) {
                  return -1;
                }
                if (a.user.name > b.user.name) {
                  return 1;
                }
                return 0;
              })
              .map((professional) => ({
                ...professional,
                checked: false,
              }))
          );
        })
        .catch((error) => {
          const { message } = processError(error, "GRAPHQL");
          launchToast(message, "error");
        });
      setLastRoute(undefined);
      setAppointmentsByProfessionals([]);
    } catch (error) {
      launchToast(processError(error, "GRAPHQL").message, "error");
    }
  };

  const handleCheckProfessional = (professional_id: string) => {
    setProfessionals((oldState) =>
      oldState.map((professional) => {
        if (professional.id !== professional_id) {
          return professional;
        }
        return {
          ...professional,
          checked: !professional.checked,
        };
      })
    );
  };

  const formatLastRoute = (route: IRoute) => {
    let status_formatted = "";
    if (route.status === "waiting") {
      status_formatted = "na fila";
    } else if (route.status === "processing") {
      status_formatted = "calculando";
    } else if (route.status === "processed") {
      status_formatted = "processada";
    } else if (route.status === "failed") {
      status_formatted = "falhou";
    } else {
      status_formatted = "erro ao definir status";
    }
    setLastRoute({
      ...route,
      status_formatted: status_formatted,
    });
  };

  const loadAppointmentsRouted = async () => {
    if (!date) {
      return;
    }
    setLoadingAppointments(true);
    appointmentsRouted(date)
      .then((appointmentsRecovered) => {
        const professionals_ids = professionals
          .filter((prof) => !!prof.checked)
          .map((professional) => professional.id) as string[];

        appointmentsRecovered.forEach((a) => {
          if (professionals_ids.every((pi) => pi !== a.professional_id)) {
            if (!!a.professional_id) {
              professionals_ids.push(a.professional_id);
            }
          }
        });

        const route_professionals_ids = lastRoute?.professionals_ids || [];
        route_professionals_ids.forEach((professional_id) => {
          if (professionals_ids.every((pi) => pi !== professional_id)) {
            professionals_ids.push(professional_id);
          }
        });
        let appointments_processed = [
          {
            professional: {
              id: "0",
              changes_pending: false,
              name: "SEM ROTA",
              truck: undefined,
            },
            appointments: [],
          },
        ] as IAppointmentByProfessionals[];

        professionals_ids.forEach((professional_id) => {
          const professional = professionals.find(
            (p) => p.id === professional_id
          );
          if (!!professional) {
            appointments_processed.push({
              professional: {
                id: professional.id,
                changes_pending: false,
                name: professional?.user.name || "SEM ROTA",
                truck: professional?.truck,
              },
              appointments: [],
            });
          }
        });

        appointmentsRecovered.forEach((appointment) => {
          const group_index = appointments_processed.findIndex((group) => {
            return group.professional.id === appointment.professional_id;
          });
          if (group_index >= 0) {
            appointments_processed[group_index].appointments = [
              ...appointments_processed[group_index].appointments,
              appointment,
            ];
          } else {
            appointments_processed[0].appointments = [
              ...appointments_processed[0].appointments,
              appointment,
            ];
          }
        });
        setAppointmentsByProfessionals(appointments_processed);
      })
      .catch((error) => {
        const { message } = processError(error, "GRAPHQL");
        launchToast(message, "error");
      })
      .finally(() => {
        setLoadingAppointments(false);
      });
  };

  const loadLastRoute = async (date_route: string) => {
    setDate(date_route);
    const routeRecovered = await getLastRouteCreatedByDate(date_route);

    if (routeRecovered) {
      formatLastRoute(routeRecovered);
    }
  };

  useEffect(() => {
    getProfessionalsActived()
      .then((professionalsRecovered) => {
        setProfessionals(
          professionalsRecovered.map((professional) => ({
            ...professional,
            checked: false,
          }))
        );
      })
      .catch((error) => {
        const { message } = processError(error, "GRAPHQL");
        launchToast(message, "error");
      });
  }, []);

  useEffect(() => {
    if (lastRoute?.status === "processed") {
      loadAppointmentsRouted();
    }
    // eslint-disable-next-line
  }, [lastRoute]);

  const handleExportFullData = async () => {
    if (!date) {
      return;
    }

    const workbook = new ExcelJS.Workbook();
    workbook.creator = user.name;
    workbook.lastModifiedBy = user.name;
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 1,
        visibility: "visible",
      },
    ];

    const { appointments, appointmentsResumePricesReports } =
      await loadAppointemtnsToExport({
        date: date,
      });

    const data_formatted = appointments.appointments.map((appointment) => {
      const service_full_price = Number(appointment.service_price / 100);
      const service_paided_price = Number(appointment.paid_price);
      const additionals_full_price = appointment.appointments_items.reduce(
        (total, additional) => total + Number(additional.item_price),
        0
      );
      const additionals_paided_price = appointment.appointments_items.reduce(
        (total, additional) => total + Number(additional.paid_price),
        0
      );

      const appointment_full_price = Number(
        service_full_price + additionals_full_price
      );
      const appointment_paided_price = Number(
        service_paided_price + additionals_paided_price
      );

      let payment = "";

      if (appointment.status >= AppointmentStatusEnum.Pago) {
        payment = "CONCLUIDO";
      } else if (
        appointment.status === AppointmentStatusEnum["Aguardando pagamento"]
      ) {
        payment = "AGUARDANDO";
      } else if (
        appointment.status ===
        AppointmentStatusEnum["Aguardando Pagamento Adicional"]
      ) {
        payment = "AGUARDANDO PAGAMENTO ADICIONAL";
      } else {
        payment = "";
      }

      let platform = "sem registro";

      if (appointment.order.platform === PlatformEnum.mobile) {
        platform = "cliente";
      } else if (appointment.order.platform === PlatformEnum.admin) {
        platform = "admin";
      } else if (appointment.order.platform === PlatformEnum.job) {
        platform = "automatico";
      } else if (appointment.order.platform === PlatformEnum.landpage) {
        platform = "landpage";
      } 
      else if (appointment.order.platform === PlatformEnum.auto_renew) {
        platform = "Renovação automática"
      }

      return {
        period: appointment.period.toString() || "sem registro",
        observations: appointment.observations || "",
        deslocation_time:
          !!appointment.on_the_way_status_hour &&
          !!appointment.on_local_status_hour
            ? calculateDistanceBetweenHours(
                appointment.on_the_way_status_hour,
                appointment.on_local_status_hour
              ).toString()
            : "",
        difference_time:
          appointment.on_the_way_status_hour &&
          appointment.service_completed_status_hour
            ? calculateDistanceBetweenHours(
                appointment.on_the_way_status_hour,
                appointment.service_completed_status_hour
              ).toString()
            : "sem registro",
        start_time: appointment.hour || "sem registro",
        end_time: appointment.service_completed_status_hour || "sem registro",
        real_start_time: appointment.on_the_way_status_hour || "",
        real_end_time: appointment.service_completed_status_hour || "",
        customer_name: appointment.customer.user.name || "sem registro",
        pet_name: appointment.pet.name || "sem registro",
        pet_breed: appointment.pet.breed?.name || "sem registro",
        pet_gender: appointment.pet.gender || "sem registro",
        pet_specie: appointment.pet.specie.name || "sem registro",
        pet_size: appointment.pet?.breed?.size
          ? appointment.pet?.breed?.size
          : calculateSizeByWeigthPet(
              appointment.pet.specie.name,
              appointment.pet.weight || 0
            ) || "sem registro",
        cep: appointment.address.cep,
        address_street: appointment.address.street || "sem registro",
        address_neighborhood:
          appointment.address.neighborhood || "sem registro",
        address_number: appointment.address.number || "sem registro",
        address_complement: appointment.address.complement || "sem registro",
        service_name: appointment.service.name || "sem registro",
        service_full_price: service_full_price,
        additionals:
          appointment.appointments_items
            .map((additional) => additional.item.name)
            .toString() || "sem registro",
        additionals_full_price: additionals_full_price,
        discount: appointment_full_price - appointment_paided_price,
        paided_price: appointment_paided_price,
        payment: payment,
        observations_admin: "",
        power_supply: appointment.power_supply
          ? PowerSupplyEnum[appointment.power_supply]
          : "sem registro",
        platform: platform,
        professional: {
          id: appointment.professional?.id,
          name: appointment.professional?.user.name,
        },
        gerado_em: String(appointment.created_at),
        total_paided_order: appointment.order.paid_price,
        pagarme_transaction_id: appointment.order.transactions
          .map((t) => t.transaction_pagseguro_id)
          .toString(),
      };
    });

    const separeted_by_professional = data_formatted.reduce<IExcelExport[]>(
      (result, row) => {
        const result_index = result.findIndex(
          (r) => r.professional.id === row.professional?.id
        );
        if (result_index >= 0) {
          result[result_index].appointments.push(row);
        } else {
          result.push({
            professional: row.professional.id
              ? row.professional
              : {
                  id: undefined,
                  name: "sem professional",
                },
            appointments: [row],
          });
        }

        return result;
      },
      []
    );

    const new_sheet = workbook.addWorksheet(date);

    const row_full_price = new_sheet.addRow([
      "FATURAMENTO",
      convertToCurrencyFormat(appointmentsResumePricesReports.total),
    ]);
    row_full_price.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EB7AA" },
      bgColor: { argb: "8EB7AA" },
    };
    row_full_price.height = 32;
    row_full_price.alignment = { vertical: "middle" };

    new_sheet.addRow([]);
    const header = new_sheet.addRow([
      "PERÍODO",
      "OBS.",
      "DESL.",
      "TEMPO",
      "INÍCIO",
      "FIM",
      "INÍCIO REAL",
      "FIM REAL",
      "TUTOR",
      "PET",
      "RAÇA",
      "SEXO",
      "ESPÉCIE",
      "PORTE",
      "CEP",
      "ENDEREÇO",
      "BAIRRO",
      "NUMERO",
      "COMPLEMENT",
      "SERVIÇO",
      "ADICIONAIS",
      "VALOR DO SERVICO",
      "VALOR DO ADICIONAL",
      "DESCONTO",
      "VALOR FINAL",
      "PAGAMENTO",
      "OBSERVAÇÕES",
      "GERADOR - TOMADA",
      "CRIADO PELO",
      "GERADO EM",
      "VALOR PEDIDO",
      "PAGARME IDS",
    ]);

    header.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EB7AA" },
      bgColor: { argb: "8EB7AA" },
    };
    header.font = { color: { argb: "FFFFFF" } };
    header.height = 32;
    header.alignment = { vertical: "middle" };

    separeted_by_professional.forEach((group) => {
      const header_professional = new_sheet.addRow([group.professional.name]);
      header_professional.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFC0" },
        bgColor: { argb: "BFBFC0" },
      };
      group.appointments.forEach((new_row) => {
        new_sheet.addRow([
          new_row.period,
          new_row.observations,
          new_row.deslocation_time,
          new_row.difference_time,
          new_row.start_time,
          new_row.end_time,
          new_row.real_start_time,
          new_row.real_end_time,
          new_row.customer_name,
          new_row.pet_name,
          new_row.pet_breed,
          new_row.pet_gender,
          new_row.pet_specie,
          new_row.pet_size,
          new_row.cep,
          new_row.address_street,
          new_row.address_neighborhood,
          new_row.address_number,
          new_row.address_complement,
          new_row.service_name,
          new_row.additionals,
          new_row.service_full_price,
          new_row.additionals_full_price,
          new_row.discount,
          new_row.paided_price,
          new_row.payment,
          new_row.observations_admin,
          new_row.power_supply,
          new_row.platform,
          new_row.gerado_em,
          new_row.total_paided_order,
          new_row.pagarme_transaction_id,
        ]);
      });
      new_sheet.addRow([]);
    });

    const writeFile = (newFileName: any, content: any) => {
      const link = document.createElement("a");

      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;",
      });

      link.download = `${newFileName}.xlsx`;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    const sheetName = `relatorio de agendamentos _ ${date}`;

    workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile(sheetName, buffer);
    });
  };

  return (
    <Styles.Container>
      <Styles.BackgroundPanel>
        <Styles.PanelHeader>
          <Styles.PanelItens>
            <Text
              text="Rotas"
              color={colors.gray.white}
              fontFamily="Open Sans"
              size={20}
              weight="600"
              marginRight={4}
              marginTop={0}
            />
          </Styles.PanelItens>
        </Styles.PanelHeader>
        <Styles.TablePanel>
          <Styles.FirstSection>
            <Styles.ActionsContainer>
              <Styles.Column style={{ width: 260 }}>
                <div>
                  <Label>Dia da rota</Label>
                  <Input
                    name="date_route"
                    type="date"
                    onChange={(e) => loadLastRoute(e.target.value)}
                  />
                </div>
                <Styles.Row style={{ marginTop: 16 }}>
                  {!lastRoute && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        text="Gerar Automatico"
                        type="button"
                        styleContainer={{ marginRight: "auto" }}
                        onClick={() => handleCreateNewRoute("AUTOMATICO")}
                        loading={loading}
                      />
                      <Button
                        text="Gerar Manual"
                        type="button"
                        styleContainer={{ marginRight: "auto", marginLeft: 16 }}
                        onClick={() => handleCreateNewRoute("MANUAL")}
                        loading={loading}
                      />
                    </div>
                  )}
                  {!!lastRoute && (
                    <Button
                      text="Descartar"
                      type="button"
                      style={{ background: colors.argon.secondaryButton }}
                      onClick={() => handleDiscardRoute()}
                    />
                  )}
                </Styles.Row>

                {!!lastRoute && (
                  <Styles.Row style={{ alignItems: "center", marginTop: 24 }}>
                    <Text
                      text={`Rota ${lastRoute.vuupt_id} - ${lastRoute.status_formatted}`}
                      color={colors.gray.dark01}
                      fontFamily="Open Sans"
                      size={16}
                      align="left"
                    />
                    {lastRoute.status !== "processed" && (
                      <Styles.ButtonUpdateStatus
                        disabled={loadingVerifyRouteStatus}
                        onClick={() => handleVerifyStatusRoute()}
                      >
                        {loadingVerifyRouteStatus ? "buscando" : "atualizar"}
                      </Styles.ButtonUpdateStatus>
                    )}
                  </Styles.Row>
                )}
                {!!lastRoute && (
                  <Styles.Row style={{ marginTop: 8 }}>
                    <Button
                      text="Exportar dados"
                      type="button"
                      style={{ background: colors.suport.blue }}
                      onClick={() => handleExportFullData()}
                    />
                  </Styles.Row>
                )}
              </Styles.Column>
            </Styles.ActionsContainer>
          </Styles.FirstSection>
        </Styles.TablePanel>
        {!lastRoute && (
          <Styles.TablePanel>
            <Styles.FirstSection>
              <Styles.ActionsContainer>
                <Styles.Column>
                  <Label>Trucks</Label>
                </Styles.Column>
              </Styles.ActionsContainer>
            </Styles.FirstSection>
            <Styles.TrucksContainer>
              {professionals.map((professional) => (
                <Styles.Row key={professional.id}>
                  <Styles.Checklist
                    checked={professional.checked}
                    style={{ marginRight: 8 }}
                    onClick={() => handleCheckProfessional(professional.id)}
                  />
                  <Text
                    text={`${
                      professional.truck?.license_plate || "sem placa"
                    } - ${
                      professional.user.name
                    } (${professional.user.roles.map((role) => role.name)})`}
                    color={colors.gray.dark01}
                    fontFamily="Open Sans"
                    size={16}
                    marginBottom={8}
                    align="left"
                  />
                </Styles.Row>
              ))}
            </Styles.TrucksContainer>
          </Styles.TablePanel>
        )}
      </Styles.BackgroundPanel>
      <Styles.BackgroundPanel style={{ marginLeft: 16 }}>
        {loadingAppointments ? (
          <Spinner size={34} color={colors.primary.default} />
        ) : (
          <ScheduleBoard data={appointmentsByProfessionals} />
        )}
      </Styles.BackgroundPanel>
    </Styles.Container>
  );
};
export default RouteDashboard;
