import React, {
    useEffect,
    useRef,
    useState
} from "react";
import { gql } from "@apollo/client";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import InputRef from "../../../../components/InputRef";
import Button from "../../../../components/Button";
import client from "../../../../services/api-graphql";
import * as Yup from "yup";
import { updateProfession, updateVet } from "../../../../services/user/UserService";
import {
    insertCnh,
    updateGroomer,
} from "../../../../services/user/UserService";
import { launchToast } from "../../../../shared/utils/launchToast";
import getValidationErrors from "../../../../shared/utils/getValidationErrors";
import { Label } from "../../../../components/Label/styles";
import ufs from "../../../../shared/utils/constants/ufs";
import Select from "../../../../components/Select";
import { getTrucks } from "../../../../services/api-graphql-calls";

import * as Styles from "./styles";

interface UserFormData {
    truck: string;
    number: string;
    category: string;
    validity: string;
    crmv: string;
    crmv_uf: string;
    truckRent: string
    outSourced: string
    report_visibility: boolean
}

interface FormRenderControll {
    [key: string]: JSX.Element;
}

const ProfessionalModal = ({ userId, onDiscard, professional, roles }: any) => {
    const formRef = useRef<FormHandles>(null);

    const [loading, setLoading] = useState(false);
    const [cnhId, setCnhId] = useState("");
    const [trucks, setTrucks] = useState([]);
    const [selectTruck, setSelectTruck] = useState({} as any);
    const [truckError, setTruckError] = useState("");
    const [professionalRecivied, setProfessionalRecivied] = useState({} as any);
    const [outSourced, setOutSourced] = useState({
        value: false,
        label: "Direto",
    });
    const [reportVisibility, setReportVisibility] = useState({
        value: true,
        label: "Sim",
    });
    const [truckRent, setTruckRent] = useState("")

    const isGroomer = roles?.some((role: { name: string }) => role.name === 'tosador' || role.name === 'banhista')
    const isVeterianarian = roles?.some((role: { name: string }) => role.name === 'veterinário')
    const profession = (isVeterianarian ? (isGroomer ? "groomerVeterinarian" : "veterinarian") : (isGroomer ? "groomer" : ""))



    useEffect(() => {
        if (!!professional) {
            formRef.current?.setData(professional);
            setProfessionalRecivied(professional);

            if (!!professional.cnh) {
                formRef.current?.setFieldValue(
                    "validity",
                    professional.cnh.validity.substring(0, 10)
                );
                formRef.current?.setFieldValue(
                    "number",
                    professional.cnh.number
                );
                formRef.current?.setFieldValue(
                    "category",
                    professional.cnh.category
                );
            }

            setCnhId(professional.cnh_id);


            setOutSourced({
                value: professional.outsourced,
                label: `${professional.outsourced ? "Terceirizado" : "Direto"}`,
            });

            setReportVisibility({
                value: professional.report_visibility,
                label: `${professional.report_visibility ? "Sim" : "não"}`,
            });

            const formattedTruckRent = (professional.truck_rent === undefined ? 'R$ 0,00' : new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(professional.truck_rent))

            setTruckRent(formattedTruckRent)

            setSelectTruck(professional.truck)

        } else {
            formRef.current?.reset();
        }
    }, [professional]);

    useEffect(() => {
        if (!!professionalRecivied) {
            let truck = trucks.find(
                (t: any) => t.value === professionalRecivied.truck_id
            );
            setSelectTruck(truck);
        }
        setTruckError("");
    }, [trucks, professionalRecivied]);

    useEffect(() => {
        loadTrucks();
    }, []);

    const loadTrucks = async () => {
        const results = (await getTrucks()) as any;

        const { trucks } = results;

        let trucksForSelect = trucks.map((r: any) => ({
            value: r.id,
            label: r.chasi_number,
        }));

        setTrucks(trucksForSelect);
    };

    const truckRentMask = (e: any) => {

        const inputValue = e.target.value;

        const numericValue = inputValue.replace(/\D/g, '');

        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(Number(numericValue) / 100);

        setTruckRent(formattedValue)
    }

    const truckRentUnmask = (value: string) => {

        const formattedValue = value?.replace('R$', '').replace(/\./g, '').replace(',', '.');;

        const numberFloat = parseFloat(formattedValue);

        return numberFloat
    }

    const handleSelectTruck = (e: any) => {
        setSelectTruck(e)
    }

    const handleSubmit = async (data: UserFormData) => {

        try {
            formRef.current?.setErrors({});

            if (isVeterianarian) {
                const schema = Yup.object().shape({
                    crmv: Yup.string().required("Número do CRM é obrigatório"),
                    crmv_uf: Yup.string()
                        .required("UF é obrigatória")
                        .oneOf(ufs, "Informe um estado válido"),
                });
                await schema.validate(data, { abortEarly: false });
            }

            if (isGroomer) {

                if (selectTruck === null || selectTruck?.length === 0 || selectTruck === undefined) {
                    setTruckError("Selecione ao menos um truck");
                }
                else {
                    setTruckError("");
                }

                const schema = Yup.object().shape({
                    truckRent: Yup.string(),
                    number: Yup.string().required("Número da CNH é obrigatória").min(11, "CNH deve conter 9 digitos").max(11, "CNH deve conter 11 digitos"),
                    category: Yup.string().required("A categoria da CNH é obrigatória"),
                    validity: Yup.string().required("A validade da CNH é obrigatória"),
                    truck: Yup.object().shape({
                        label: Yup.string().required('A etiqueta do caminhão é obrigatória'),
                    }),
                })
                await schema.validate({ ...data, truck: selectTruck }, { abortEarly: false });
            }
            try {

                let auxID = cnhId;

                const unMaskedTruckRent = truckRentUnmask(truckRent)

                if (!professionalRecivied || professionalRecivied === null || !professionalRecivied?.id) {
                    await client.mutate({
                        mutation: gql`
                    mutation($professional: UpdateProfessionalInputType!, $createProfessionalByAdminId: String!) {
                      createProfessionalByAdmin(professional: $professional, id: $createProfessionalByAdminId) {
                        id
                      }
                    }
                  `,
                        variables: {
                            createProfessionalByAdminId: userId,
                            professional: {
                                crmv: data.crmv,
                                crmv_uf: data.crmv_uf,
                                truck_id: data.truck,
                                category: data.category,
                                number: data.number,
                                validity: data.validity,
                                truck_rent: data.truckRent,
                            },
                        }
                    });
                    launchToast("Profissional criado com sucesso", "success");
                    setLoading(false);
                    return;
                }

                if (auxID === null) {
                    if (data.number !== "") {
                        const { number, category, validity } = data;
                        let results = await client.mutate({
                            mutation: gql`
                              mutation CreateCnhByAdmin(
                                $validity: String!
                                $category: String!
                                $cnhNumber: String!
                              ) {
                                createCnhByAdmin(
                                  validity: $validity
                                  category: $category
                                  cnh_number: $cnhNumber
                                ) {
                                  id
                                }
                              }
                            `,
                            variables: {
                              validity,
                              category,
                              cnhNumber: number,
                            },
                          }) as any

                        setCnhId(results.id);
                        auxID = results.id;
                    }
                }

                await updateProfession({
                    professionalId: professionalRecivied.id, 
                    truck_id: selectTruck.value,
                    cnh_id: auxID, 
                    truck_rent: unMaskedTruckRent, 
                    outsourced: outSourced.value, 
                    ...data,
                    report_visibility: reportVisibility.value
                });
                launchToast("Profissional salvo com sucesso", "success");
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        } catch (error) {
            // @ts-ignore:next-line
            const errors = getValidationErrors(error);

            formRef.current?.setErrors(errors);
            launchToast("Verifique o preenchimento dos dados", "error");
        }
        setLoading(false);
        return false;
    }

    const handleDiscard = () => {
        formRef.current?.reset();
        formRef.current?.setErrors({});
        setTruckError("");
        setCnhId("")
        onDiscard();
        setSelectTruck([])
        setOutSourced({
            value: false,
            label: "Direto",
        })
        setTruckRent('R$ 0,00')
    };

    const formRenderControll: FormRenderControll = {
        veterinarian: <>
            <Styles.FormDiv>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <div style={{ height: "80vh" }}>
                        <Styles.FormTitle>Veterinario</Styles.FormTitle>
                        <Styles.row>
                            <Styles.field>
                                <Label>crm</Label>
                                <InputRef
                                    placeholder="Digite o número do CRM"
                                    name="crmv"
                                    type="text"
                                    containerStyle={{ width: "33rem" }}
                                    maxLength={6}
                                />
                            </Styles.field>
                            <Styles.field>
                                <Label>uf</Label>
                                <InputRef
                                    placeholder="Digite a sigla da UF. Ex: SP."
                                    name="crmv_uf"
                                    type="text"
                                    containerStyle={{ width: "33rem" }}
                                    maxLength={2}
                                />
                            </Styles.field>
                        </Styles.row>
                    </div>
                    <Styles.rowButton>
                        <Styles.WhiteButton type="button" onClick={handleDiscard}>
                            Descartar
                        </Styles.WhiteButton>
                        <Button
                            text="Salvar"
                            type="submit"
                            loading={loading}
                            styleContainer={{ minWith: "100px" }}
                        />
                    </Styles.rowButton>
                </Form>
            </Styles.FormDiv>
        </>,
        groomer: <>
            <Styles.FormDiv>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <div style={{ height: "80vh" }}>
                        <Styles.FormTitle>Tipo de Contrato</Styles.FormTitle>
                        <Styles.row>
                            <Styles.field>
                                <Label>Contrato</Label>
                                <Select
                                    name="contract"
                                    containerStyle={{ width: "33rem" }}
                                    placeholder="contrato"
                                    options={[{ value: false, label: "Direto" }, { value: true, label: "Terceirizado" }]}
                                    value={outSourced}
                                    onChange={(e: any) => setOutSourced(e)}
                                />
                            </Styles.field>
                            <Styles.field>
                                <Label>Valor do Aluguel (quinzenal)</Label>
                                <InputRef
                                    placeholder="Digite o valor do aluguél"
                                    name="truckRent"
                                    type="text"
                                    value={truckRent}
                                    onChange={truckRentMask}
                                />
                            </Styles.field>
                            <Styles.field>
                                <Label>Mostrar Relatório Financeiro</Label>
                                <Select
                                    name="report_visibility"
                                    containerStyle={{ width: "33rem" }}
                                    placeholder="contrato"
                                    options={[{ value: false, label: "Não" }, { value: true, label: "Sim" }]}
                                    value={reportVisibility}
                                    onChange={(e: any) => setReportVisibility(e)}
                                />
                            </Styles.field>
                        </Styles.row>
                        <Styles.FormTitle>Tosador / Banhista</Styles.FormTitle>
                        <Styles.row>
                            <Styles.field>
                                <Label>truck</Label>
                                <Select
                                    name="truck"
                                    containerStyle={{ width: "33rem" }}
                                    placeholder="Truck"
                                    options={trucks}
                                    error={truckError}
                                    value={selectTruck}
                                    onChange={(e) => handleSelectTruck(e)}
                                />
                            </Styles.field>
                            <Styles.field>
                                <Label>número da cnh</Label>
                                <InputRef
                                    placeholder="Digite o número da CNH"
                                    name="number"
                                    type="text"
                                    maxLength={11}
                                />
                            </Styles.field>
                        </Styles.row>
                        <Styles.row>
                            <Styles.field>
                                <Label>validade</Label>
                                <InputRef
                                    placeholder="Informe a validade da CNH"
                                    name="validity"
                                    type="date"
                                    containerStyle={{ width: "33rem" }}
                                />
                            </Styles.field>
                            <Styles.field>
                                <Label>categoria</Label>
                                <InputRef
                                    placeholder="Digite a categoria da CNH"
                                    name="category"
                                    type="text"
                                    maxLength={2}
                                />
                            </Styles.field>
                        </Styles.row>
                    </div>
                    <Styles.rowButton>
                        <Styles.WhiteButton type="button" onClick={handleDiscard}>
                            Descartar
                        </Styles.WhiteButton>
                        <Button
                            text="Salvar"
                            type="submit"
                            loading={loading}
                            styleContainer={{ minWith: "100px" }}
                        />
                    </Styles.rowButton>
                </Form>
            </Styles.FormDiv>
        </>,
        groomerVeterinarian: <>
            <Styles.FormDiv>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <div style={{ height: "80vh" }}>
                        <Styles.FormTitle>Tipo de Contrato</Styles.FormTitle>
                        <Styles.row>
                            <Styles.field>
                                <Label>contract</Label>
                                <Select
                                    name="contract"
                                    containerStyle={{ width: "33rem" }}
                                    placeholder="contrato"
                                    options={[{ value: false, label: "Direto" }, { value: true, label: "Terceirizado" }]}
                                    error={truckError}
                                    value={outSourced}
                                    onChange={(e: any) => setOutSourced(e)}
                                />
                            </Styles.field>
                            <Styles.field>
                                <Label>Valor do Aluguel</Label>
                                <InputRef
                                    placeholder="Digite o valor do aluguél"
                                    name="truckRent"
                                    type="text"
                                    value={truckRent}
                                    onChange={truckRentMask}
                                />
                            </Styles.field>
                        </Styles.row>
                        <Styles.FormTitle>Tosador</Styles.FormTitle>
                        <Styles.row>
                            <Styles.field>
                                <Label>truck</Label>
                                <Select
                                    name="truck"
                                    containerStyle={{ width: "33rem" }}
                                    placeholder="Truck"
                                    options={trucks}
                                    error={truckError}
                                    value={selectTruck}
                                    onChange={(e: any) => setSelectTruck(e)}
                                />
                            </Styles.field>
                            <Styles.field>
                                <Label>número da cnh</Label>
                                <InputRef
                                    placeholder="Digite o número da CNH"
                                    name="number"
                                    type="text"
                                    maxLength={11}
                                />
                            </Styles.field>
                        </Styles.row>
                        <Styles.row>
                            <Styles.field>
                                <Label>validade</Label>
                                <InputRef
                                    placeholder="Informe a validade da CNH"
                                    name="validity"
                                    type="date"
                                    containerStyle={{ width: "33rem" }}
                                />
                            </Styles.field>
                            <Styles.field>
                                <Label>categoria</Label>
                                <InputRef
                                    placeholder="Digite a categoria da CNH"
                                    name="category"
                                    type="text"
                                    maxLength={2}
                                />
                            </Styles.field>
                        </Styles.row>
                        <Styles.FormTitle>Veterinário</Styles.FormTitle>
                        <Styles.row>
                            <Styles.field>
                                <Label>crm</Label>
                                <InputRef
                                    placeholder="Digite o número do CRM"
                                    name="crmv"
                                    type="text"
                                    containerStyle={{ width: "33rem" }}
                                    maxLength={6}
                                />
                            </Styles.field>
                            <Styles.field>
                                <Label>uf</Label>
                                <InputRef
                                    placeholder="Digite a sigla da UF. Ex: SP."
                                    name="crmv_uf"
                                    type="text"
                                    containerStyle={{ width: "33rem" }}
                                    maxLength={2}
                                />
                            </Styles.field>
                        </Styles.row>

                    </div>
                    <Styles.rowButton>
                        <Styles.WhiteButton type="button" onClick={handleDiscard}>
                            Descartar
                        </Styles.WhiteButton>
                        <Button
                            text="Salvar"
                            type="submit"
                            loading={loading}
                            styleContainer={{ minWith: "100px" }}
                        />
                    </Styles.rowButton>
                </Form>
            </Styles.FormDiv >
        </>
    }

    return (
        <>
            {formRenderControll[profession]}
        </>
    );
}
export default ProfessionalModal