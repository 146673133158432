import { gql } from "@apollo/client";
import client from "../../../services/api-graphql";
import IAppointment from "../../../types/IAppointment";
import { IAppointmentFiltersFormData } from "./forms/AppointmentFiltersForm/types";
import { ITotalResumePricesProps } from "./types";

type IProps = {
  filters: IAppointmentFiltersFormData;
  pagination: boolean;
};

type IResults = {
  appointmentsResumePricesReports: ITotalResumePricesProps;
  appointments: { totalPages: number; appointments: IAppointment[] };
};

const loadReportResumePrices = async ({
  filters,
  pagination,
}: IProps): Promise<IResults> => {
  const results = await client.query<IResults>({
    query: gql`
      query (
        $to: String!
        $from: String!
        $filters: FiltersAppointmentInputType
      ) {
        appointmentsResumePricesReports(to: $to, from: $from) {
          total
          details_by_date {
            date
            total
          }
          details_by_professional {
            professional_name
            total
          }
        }
        appointments(filters: $filters) {
          totalPages
          appointments {
            id
            observations
            on_the_way_status_hour
            service_completed_status_hour
            date
            period
            hour
            status
            service_price
            paid_price
            power_supply
            created_at
            execution_time
            pack_frequency
            order {
              platform
              paid_price
              discount_by_admin
              total_discount
              transactions {
                id
                transaction_pagseguro_id
                provider
                type
              }
              appointments {
                id
              }
              coupon_relation {
                code
              }
            }
            customer {
              user {
                name
                cpf
              }
            }
            professional {
              id
              user {
                id
                name
              }
            }
            pet {
              name
              gender
              breed {
                id
                name
                size
              }
              specie {
                id
                name
              }
              weight
            }
            address {
              cep
              street
              neighborhood
              number
            }
            service {
              name
            }
            appointments_items {
              item_price
              paid_price
              item {
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      from: filters.start_date,
      to: filters.end_date,
      filters: {
        where: {
          status: filters.status,
          categories: filters.categories,
          tutor_name: filters.tutor_name,
          from: filters.start_date,
          to: filters.end_date,
        },
        ...(pagination && {
          pagination: {
            limit: 20,
            page: 1,
          },
        }),
        orderBy: [
          {
            date: "asc",
          },
          {
            period: "asc",
          },
          {
            hour: "asc",
          },
        ],
      },
    },
  });
  return results.data;
};

const loadAppointmentsByRange = async ({
  filters,
  page,
}: {
  filters: IAppointmentFiltersFormData;
  page: number;
}): Promise<{ appointments: IAppointment[] }> => {
  const results = await client.query<{
    appointments: { totalPages: number; appointments: IAppointment[] };
  }>({
    query: gql`
      query ($filters: FiltersAppointmentInputType) {
        appointments(filters: $filters) {
          totalPages
          appointments {
            id
            observations
            on_the_way_status_hour
            service_completed_status_hour
            date
            period
            hour
            status
            service_price
            paid_price
            power_supply
            order {
              platform
              coupon_relation {
                code
              }
            }
            customer {
              user {
                name
                cpf
              }
            }
            professional {
              id
              user {
                id
                name
              }
            }
            pet {
              name
              gender
              breed {
                id
                name
                size
              }
              specie {
                id
                name
              }
              weight
            }
            address {
              cep
              street
              neighborhood
              number
            }
            service {
              name
            }
            appointments_items {
              item_price
              paid_price
              item {
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      filters: {
        where: {
          status: filters.status,
          categories: filters.categories,
          tutor_name: filters.tutor_name,
          from: filters.start_date,
          to: filters.end_date,
        },
        pagination: {
          limit: 20,
          page: page,
        },
        orderBy: [
          {
            date: "asc",
          },
          {
            period: "asc",
          },
          {
            hour: "asc",
          },
        ],
      },
    },
  });

  return results.data.appointments;
};

export { loadAppointmentsByRange, loadReportResumePrices };

