import { gql } from "@apollo/client";
import client from "../../services/api-graphql";
import RefundTypeEnum from "../../shared/enums/RefundTypeEnum";
import { launchToast } from "../../shared/utils/launchToast";
import processError from "../../shared/utils/processError";
import IAppointment from "../../types/IAppointment";
import IOrder from "../../types/IOrder";

interface IFiltersAppointments {
  pagination?: {
    limit: number;
    page: number;
  };
  orderBy?: {
    date: String;
  }[];
  where: {
    from?: string;
    to?: string;
    order_id?: string;
  };
}

type ISearchAppointmentsProps = {
  appointments: IAppointment[];
  totalPages: number;
};

export const GET_APPOINTMENTS = gql`
  query Appointments($filters: FiltersAppointmentInputType) {
    appointments(filters: $filters) {
      totalPages
      appointments {
        id
        date
        period
        created_at
        service_price
        paid_price
        price_fix
        price_fix_reason
        status
        observations
        service_id
        pack_id
        pack_frequency
        order_id
        platform
        service {
          id
          name
          category {
            id
            name
          }
          groups {
            id
            name
            items {
              id
              name
            }
          }
        }
        pet {
          name
          weight
          specie {
            id
            name
          }
          breed {
            name
            size
          }
        }
        customer_id
        customer {
          user {
            id
            name
          }
        }
        order {
          id
          increase_by_admin
          increase_reason
          discount_by_admin
          pack_id
          paid_price
          total_discount
          pending_price
          transactions {
            type
          }
          sales_order {
            id
            delivery_address
            paid_price
            saleAddress {
              cep
              street
              number
              neighborhood
            }
            salesProducts {
              product_id
              quantity
              price_per_product
              product {
                id
                name
              }
            }
          }
        }
        address {
          id
          cep
          state
          city
          neighborhood
          street
          number
          complement
        }
        appointments_items {
          item_price
          paid_price
          item {
            id
            name
          }
        }
        professional {
          user {
            name
          }
        }
      }
    }
  }
`;

const searchOrder = async (order_id: string): Promise<IOrder> => {
  const results = await client.query({
    query: gql`
      query ($orderId: String!) {
        order(order_id: $orderId) {
          id
          sales_order {
            id
            delivery_address
            paid_price
            saleAddress {
              cep
              street
              number
              neighborhood
            }
            salesProducts {
              product_id
              quantity
              price_per_product
              product {
                id
                name
              }
            }
          }
          appointments {
            id
            date
            period
            created_at
            service_price
            paid_price
            price_fix
            price_fix_reason
            status
            observations
            service_id
            pack_frequency
            order_id
            platform
            service {
              id
              name
              category {
                id
                name
              }
              groups {
                id
                name
                items {
                  id
                  name
                }
              }
            }
            pet {
              name
              weight
              specie {
                id
                name
              }
              breed {
                name
                size
              }
            }
            customer_id
            customer {
              user {
                id
                name
              }
            }
            order {
              id
              increase_by_admin
              increase_reason
              discount_by_admin
              pack_id
              transactions {
                type
              }
            }
            address {
              id
              cep
              state
              city
              neighborhood
              street
              number
            }
            appointments_items {
              item_price
              paid_price
              item {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      orderId: order_id,
    },
    fetchPolicy: "no-cache",
  });
  return results.data.order;
};

const searchAppointments = async (
  filters?: IFiltersAppointments
): Promise<ISearchAppointmentsProps> => {
  try {
    const results = await client.query({
      query: GET_APPOINTMENTS,
      variables: {
        filters: filters,
      },
      fetchPolicy: "no-cache",
    });
    return results.data.appointments;
  } catch (error) {
    launchToast(processError(error, "GRAPHQL").message, "error");
    return {
      totalPages: 0,
      appointments: [],
    };
  }
};

const cancelAppointment = async (payload: {
  appointment_id: string;
  refund_type: RefundTypeEnum;
}): Promise<IAppointment> => {
  const results = await client.mutate({
    mutation: gql`
      mutation ($refundType: Float!, $appointmentId: String!) {
        cancelAppointment(
          refund_type: $refundType
          appointment_id: $appointmentId
        ) {
          id
          date
          period
          created_at
          service_price
          paid_price
          price_fix
          price_fix_reason
          status
          observations
          service_id
          platform
          service {
            id
            name
            category {
              id
              name
            }
            groups {
              id
              name
              items {
                id
                name
              }
            }
          }
          pet {
            name
            weight
            specie {
              id
              name
            }
            breed {
              name
              size
            }
          }
          customer_id
          customer {
            user {
              id
              name
            }
          }
          order {
            id
            increase_by_admin
            increase_reason
            discount_by_admin
            pack_id
            transactions {
              type
            }
          }
          address {
            id
            cep
            state
            city
            neighborhood
            street
            number
          }
          appointments_items {
            item_price
            paid_price
            item {
              id
              name
            }
          }
        }
      }
    `,
    variables: {
      refundType: Number(payload.refund_type),
      appointmentId: payload.appointment_id,
    },
  });

  return results.data.cancelAppointment;
};

export { cancelAppointment, searchAppointments, searchOrder };
