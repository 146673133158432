enum TypePaymentEnum {
  card = 1,
  link = 2,
  pix = 3,
  'transferencia/pix avulso' = 4,
  dinheiro = 5,
  newlink = 6,
  'link avulso' = 7,
  definir = 0,
  gratuito = 9
};

export default TypePaymentEnum;
