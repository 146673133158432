import React, { useEffect, useState } from "react";
import { ReactComponent as Active } from "../../assets/svg/generic/active.svg";
import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import { launchToast } from "../../shared/utils/launchToast";
import UserModal from "./UserModal";
import { PanelHeader } from "components/PanelHeader";
import { Search } from "components/Search";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import { headers } from "./utils/columns";
import { getAllUsers, inactivetUser } from "../../services/user/UserService";
import Loading from "../../components/Loading";
import MainContainer from "components/MainContainer";

interface UsersResult {
  users: any[];
  totalPages: number;
}

interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  status: boolean;
  status_description: string;
  cpf: string;
  phone: string;
  birthday: string;
  address: any; // Definir o tipo correto para o endereço
  user: any; // Definir o tipo correto para o usuário
}

const UserPanel: React.FC = () => {
  const [data, setData] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [limit, setLimit] = useState(10);
  const [wordSearch, setWordSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [timer, setTimer] = useState(null as any);
  const [sort, setSort] = useState<{ field: string; direction: string }>({
    field: "",
    direction: "",
  });

  const loadUsers = async () => {
    setLoading(true);
    setIsModalVisible(false);
    let orderBy: any[] = [{ name: "asc" }];

    if (!!sort.field) {
      const { field, direction } = sort;
      switch (field) {
        case "name":
          orderBy = [{ name: direction }];
          break;
        case "email":
          orderBy.push({ email: direction });
          break;
        case "status_description":
          orderBy.push({ status: direction });
          break;
      }
    }

    try {
      const results = await getAllUsers({
        pagination: { limit, page: currentPage },
        orderBy,
        where: {
          ...(wordSearch && { wordSearch }),
        },
      });

      const { users, totalPages } = results as unknown as UsersResult;
      setTotalPage(totalPages);

      const processedData = users.map((user) => {
        const {
          id,
          name,
          email,
          roles,
          status,
          cpf,
          phone,
          birthday,
          addresses,
          address_main_id,
        } = user;

        let role = "";
        for (let i = 0; i < roles.length; i++) {
          if (i !== 0) {
            role += ", " + roles[i].name;
          } else {
            role = roles[i].name;
          }
        }

        let status_description = status ? "Ativo" : "Inativo";

        let address = addresses.find(
          (address: any) => address.id === address_main_id
        );

        const actions = [
          {
            name: "Editar",
            icon: <EditButton />,
            action: handleEditUser,
          },
          {
            name: "Desativar/Ativar",
            icon: <Active />,
            action: handleInactiveUser,
          },
        ];

        return {
          id,
          name,
          email,
          role,
          status,
          status_description,
          cpf,
          phone,
          birthday,
          address,
          user,
          actions,
        };
      });

      setData(processedData);
    } catch (error) {
      launchToast("Houve um erro na requisição", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimer(
      setTimeout(function () {
        loadUsers();
      }, 500)
    );
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [limit, currentPage, sort, wordSearch]);

  const handleInactiveUser = async (id: string, processedData: any) => {
    let users = [...processedData];
    let index = users.findIndex((user) => user.id === id);
    users[index].status = !users[index].status;
    users[index].status_description = users[index].status ? "Ativo" : "Inativo";
    await inactivetUser(id, users[index], users[index].status);
    loadUsers();
  };

  const handleEditUser = (id: string, processedData: any) => {
    let user = processedData.find((user: any) => {
      return user.id === id;
    });
    setSelectedUser(user?.user);
    setIsModalVisible(true);
  };

  const onSetPageSize = (pageSize: number) => {
    setLimit(pageSize);
  };

  const handleNewUser = () => {
    setSelectedUser({});
    setIsModalVisible(true);
  };

  const handleClose = () => {
    if (isModalVisible) {
      setData([]);
      loadUsers();
    }
  };

  const handleWordSearch = (event: string) => {
    setCurrentPage(1);
    setWordSearch(event);
  };

  // eslint-disable-next-line
  const handleSort = (field: string) => {
    let direction = "desc";

    if (!!sort.field && sort.field === field && sort.direction === "desc") {
      direction = "asc";
    }

    setSort({ field, direction });
  };

  return (
    <>
      {loading && <Loading />}
      <MainContainer>
        <PanelHeader
          title="Usuários"
          onClick={handleNewUser}
          isNew={true}
          isFilterModalVisible={false}
        />
        <Search
          limit={limit}
          onSetPageSize={onSetPageSize}
          placeholder="Pesquisar por nome ou e-mail"
          wordSearch={wordSearch}
          setSearch={handleWordSearch}
          isFilterForRegister={true}
        />
        <Table data={data} headers={headers} />
        <Pagination
          totalPage={totalPage}
          pageIndex={currentPage}
          setPage={setCurrentPage}
        />
      </MainContainer>
      <UserModal
        visible={isModalVisible}
        onCloseModal={handleClose}
        userRecivied={selectedUser}
      />
    </>
  );
};

export default UserPanel;
