import React from "react";
import Modal from "../Modal";
import Text from "../Text";
import { useAuth } from "../../hooks/Auth";
import RefundTypeEnum from "../../shared/enums/RefundTypeEnum";
import colors from "../../shared/utils/constants/colors";
import * as Styles from "./styles";

type IProps = {
  visible: boolean;
  onCloseModal: () => void;
  onSubmit: (refund_type: RefundTypeEnum) => void;
  loading: boolean;
};

const ChooseRefundTypeModal = ({
  visible,
  onCloseModal,
  onSubmit,
  loading,
}: IProps) => {
  const { user } = useAuth();

  return (
    <>
      <Modal visible={visible} closeModal={() => onCloseModal()}>
        <Styles.Header>
          <Text
            text="Estorno de valores pagos"
            color={colors.argon.darkBlue}
            fontFamily="Open Sans"
            size={17}
            weight="600"
          />

          <Text
            text="X"
            color={colors.gray.dark01}
            fontFamily="Open Sans"
            size={17}
            weight="600"
            onTextClick={() => onCloseModal()}
          />
        </Styles.Header>
        <Styles.line></Styles.line>

        <div style={{ width: "100%" }}>
          <Styles.FormDiv>
            <Styles.Options>
              {!!user.roles.find((role) => role.name === "financeiro") && (
                <Styles.Option
                  disabled={loading}
                  onClick={() => onSubmit(RefundTypeEnum.automatic)}
                >
                  Automatico
                </Styles.Option>
              )}
              <Styles.Option
                disabled={loading}
                onClick={() => onSubmit(RefundTypeEnum.manual)}
              >
                Manual
              </Styles.Option>
              <Styles.Option
                disabled={loading}
                onClick={() => onSubmit(RefundTypeEnum.coupon)}
              >
                Gerar cupom
              </Styles.Option>
              <Styles.Option
                disabled={loading}
                onClick={() => onSubmit(RefundTypeEnum.no_refund)}
              >
                Sem estorno
              </Styles.Option>
            </Styles.Options>
          </Styles.FormDiv>
        </div>
        <Text
          text="*Caso o pedido/agendamento tenha sido pago, é necessário realizar o estorno do valor pago, caso contrário, escolha a opção 'Sem estorno'."
          fontFamily="Open Sans"
          size={15}
          weight="400"
          marginTop={40}
        />
      </Modal>
    </>
  );
};
export default ChooseRefundTypeModal;
