import { gql } from "@apollo/client";
import client from "../../services/api-graphql";
import IAppointment from "../../types/IAppointment";
import IProfessional from "../../types/IProfessional";
import IRoute from "../../types/IRoute";

export interface ITotalResumePricesProps {
  total: number;
  details_by_date: {
    date: string;
    total: number;
  }[];
  details_by_professional: {
    professional_name: string;
    total: number;
  }[];
}

const getProfessionalsActived = async (): Promise<IProfessional[]> => {
  const response = await client.query({
    query: gql`
    query($filters: FiltersProfessionalInputType) {
      professionals(filters: $filters) {
        id
        user {
          name
          roles {
            name
          }
        }
        truck {
          id
          license_plate
        }
      }
    }
    `,
    variables: {
      filters: {
        where: {
          status: true
        }
      },
    },
    fetchPolicy: "no-cache",
  });

  return response.data.professionals.sort((a: any, b: any) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.user.name.toUpperCase();
    const bandB = b.user.name.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  });
};

const appointmentsRouted = async (date: string): Promise<IAppointment[]> => {
  const response = await client.query({
    query: gql`
      query($date: String!) {
        appointmentsRouted(date: $date) {
          id
          professional_id
          customer {
            user {
              name
            }
          }
          service {
            name
          }
          professional {
            id
            user {
              name
            }
            truck {
              license_plate
            }
          }
          pet {
            name
            specie {
              name
            }
            breed {
              name
            }
          }
          address {
            state
            city
            neighborhood
            street
            number
          }
          date
          period
          hour
          execution_time   
          skills_necessary       
        }
      }
    `,
    variables: {
      date: date
    },
    fetchPolicy: "no-cache",
  });
  return response.data.appointmentsRouted;
};

const getLastRouteCreatedByDate = async (date: string): Promise<IRoute | undefined> => {
  const response = await client.query({
    query: gql`
      query($date: String!) {
        getLastRouteCreatedByDate(date: $date) {
          id
          vuupt_id
          date
          status
          professionals_ids
        }
      }
    `,
    variables: {
      date: date,
    },
    fetchPolicy: "no-cache",
  });
  return response.data.getLastRouteCreatedByDate;
};

const verifyRouteVupptStatus = async (vuupt_id: number): Promise<IRoute> => {
  const response = await client.query({
    query: gql`
      query($vuuptRouteId: Float!) {
        verifyRouteVupptStatus(vuupt_route_id: $vuuptRouteId) {
          status
          vuupt_id
        }
      }
    `,
    variables: {
      vuuptRouteId: vuupt_id
    },
    fetchPolicy: "no-cache",
  });
  return response.data.verifyRouteVupptStatus;
};

const createnewRoute = async (payload: {
  date: string;
  professionals_ids: string[];
  type: 'MANUAL' | 'AUTOMATICO';
}): Promise<IRoute> => {
  const response = await client.mutate({
    mutation: gql`
      mutation($professionalsIds: [String!]!, $date: String!, $type: String!) {
        createRouteAppointmentsByDate(professionals_ids: $professionalsIds, date: $date, type: $type) {
          vuupt_id
          status
        }
      }
    `,
    variables: {
      date: payload.date,
      professionalsIds: payload.professionals_ids,
      type: payload.type
    },
    fetchPolicy: "no-cache",
  });
  return response.data.createRouteAppointmentsByDate;
};

const cancelRouteByDate = async (date: string): Promise<IRoute> => {
  const response = await client.mutate({
    mutation: gql`
      mutation($date: String!) {
        cancelRouteByDate(date: $date) {
          id
        }
      }
    `,
    variables: {
      date: date,
    },
    fetchPolicy: "no-cache",
  });
  return response.data.cancelRouteByDate;
};

const updateAppointmentInRoute = async (appointments: {
  id: string;
  professional_id?: string | null;
  hour?: string | null;
}[]): Promise<IAppointment[]> => {
  const results = await client.mutate({
    mutation: gql`
      mutation($appointments: [AppointmentRouteInputType!]!) {
        updateAppointmentsInRoute(appointments: $appointments) {
          id
          customer {
            user {
              name
            }
          }
          service {
            name
          }
          professional {
            id
            user {
              name
            }
          }
          pet {
            name
            specie {
              name
            }
            breed {
              name
            }
          }
          address {
            state
            city
            neighborhood
            street
            number
          }
          date
          period
          hour
          execution_time
          skills_necessary
        }
      }
    `,
    variables: {
      appointments: appointments
    },
    fetchPolicy: "no-cache",
  });
  return results.data.updateAppointmentsInRoute;
};

const updateAppointmentExecutionTime = async ({ appointment_id, execution_time }: {
  appointment_id: string;
  execution_time: number;
}): Promise<IAppointment> => {
  const results = await client.mutate({
    mutation: gql`
      mutation($executionTime: Float!, $appointmentId: String!) {
        updateAppointmentExecutionTime(
          execution_time: $executionTime
          appointment_id: $appointmentId
        ) {
          id
          customer {
            user {
              name
            }
          }
          service {
            name
          }
          professional {
            id
            user {
              name
            }
          }
          pet {
            name
            specie {
              name
            }
            breed {
              name
            }
          }
          address {
            state
            city
            neighborhood
            street
            number
          }
          date
          period
          hour
          execution_time
          skills_necessary
        }
      }
    `,
    variables: {
      executionTime: execution_time,
      appointmentId: appointment_id
    },
    fetchPolicy: "no-cache",
  });
  return results.data.updateAppointmentExecutionTime;
};


const loadAppointemtnsToExport = async ({
  date
}: { date: string }): Promise<{
  appointmentsResumePricesReports: ITotalResumePricesProps;
  appointments: { appointments: IAppointment[] };
}> => {
  const results = await client.query<{
    appointmentsResumePricesReports: ITotalResumePricesProps;
    appointments: { appointments: IAppointment[] };
  }>({
    query: gql`
      query (
        $to: String!
        $from: String!
        $filters: FiltersAppointmentInputType
      ) {
        appointmentsResumePricesReports(to: $to, from: $from) {
          total
          details_by_date { 
            date
            total
          }
          details_by_professional {
            professional_name
            total
          }
        }
        appointments(filters: $filters) {
          totalPages
          appointments {
            id
            observations
            on_the_way_status_hour
            service_completed_status_hour
            date
            period
            hour
            status
            service_price
            paid_price
            power_supply
            created_at
            order {
              platform
              paid_price
              transactions {
                transaction_pagseguro_id
              }
            }
            customer {
              user {
                name
              }
            }
            professional {
              id
              user {
                id
                name
              }
            }
            pet {
              name
              gender
              breed {
                id
                name
                size
              }
              specie {
                id
                name
              }
              weight
            }
            address {
              cep
              street
              neighborhood
              number
            }
            service {
              name
            }           
            appointments_items {
              item_price
              paid_price
              item {
                name
              }
            }
            skills_necessary
          }
        }
      }
    `,
    variables: {
      from: date,
      to: date,
      filters: {
        where: {
          statusNotEqual: -1,
          from: date,
          to: date,
        },
        orderBy: [
          {
            date: "asc"
          },
          {
            period: "asc"
          },
          {
            hour: "asc"
          }
        ]
      },
    },
  });

  return results.data;
};

export {
  getProfessionalsActived,
  appointmentsRouted,
  getLastRouteCreatedByDate,
  createnewRoute,
  verifyRouteVupptStatus,
  cancelRouteByDate,
  updateAppointmentInRoute,
  updateAppointmentExecutionTime,
  loadAppointemtnsToExport
};
